import { createSlice } from "@reduxjs/toolkit";
import { getDataProduct } from "../actions/productCategory.action";

const initialState = {
  dataProductCategory: {},
  loading: false,
};

export const ProductCategoryReducer = createSlice({
  name: "dataProductCategory",
  initialState,
  reducers: {
    setCategoryFilter: (state, action) => {
      state.dataProductCategory.categoryFilter = action.payload.reverse();
    },
    setSimilarColorFilter: (state, action) => {
      state.dataProductCategory.similarColorFilter = action.payload.reverse();
    },
    setMaterialFilter: (state, action) => {
      state.dataProductCategory.materialFilter = action.payload.reverse();
    },
    setStyleFilter: (state, action) => {
      state.dataProductCategory.styleFilter = action.payload.reverse();
    },
    setDesignFilter: (state, action) => {
      state.dataProductCategory.shapeFilter = action.payload.reverse();
    },
  },
  extraReducers: (builder)=>{
    builder.addCase(getDataProduct.pending, (state)=>{
      state.loading = true;
      state.dataProductCategory.products = undefined;
        state.dataProductCategory.totalProducts = undefined;
    }).addCase(getDataProduct.fulfilled, (state, action)=>{
      state.loading = false;
      try {
        state.dataProductCategory.products = action.payload?.data;
        state.dataProductCategory.totalProducts = action.payload?.count;
      } catch (e) {
        console.log(e);
      }
    }).addCase(getDataProduct.rejected, (state, action)=>{
      state.loading = false;
      state.error = action.error.message ?? 'Unknown Error'
    })
  }
 
});

export const {
  setProducts,
  setTotalProducts,
  setCategoryFilter,
  setSimilarColorFilter,
  setMaterialFilter,
  setStyleFilter,
  setDesignFilter,
} = ProductCategoryReducer.actions;

export default ProductCategoryReducer.reducer;

