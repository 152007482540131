const ICHotline = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="currentColor"
    >
      <path d="M12.9603 13.6668C11.704 13.6668 10.4418 13.3747 9.17383 12.7905C7.90594 12.2063 6.741 11.3822 5.679 10.3182C4.61711 9.25405 3.79405 8.08905 3.20983 6.82316C2.62561 5.55738 2.3335 4.29633 2.3335 3.04C2.3335 2.83811 2.40016 2.66988 2.5335 2.53533C2.66683 2.40077 2.8335 2.3335 3.0335 2.3335H5.20783C5.37616 2.3335 5.52466 2.38844 5.65333 2.49833C5.782 2.60811 5.86383 2.74377 5.89883 2.90533L6.281 4.86683C6.30744 5.04883 6.30188 5.20522 6.26433 5.336C6.22666 5.46677 6.15911 5.57661 6.06166 5.6655L4.522 7.16433C4.76977 7.61811 5.05288 8.04738 5.37133 8.45216C5.68966 8.85683 6.03433 9.24333 6.40533 9.61166C6.77111 9.97755 7.16 10.3173 7.572 10.631C7.984 10.9447 8.42888 11.2366 8.90666 11.5067L10.4027 9.99766C10.507 9.88911 10.6333 9.813 10.7817 9.76933C10.9299 9.72577 11.0839 9.71511 11.2438 9.73733L13.095 10.1143C13.2633 10.1588 13.4007 10.2447 13.5072 10.372C13.6136 10.4993 13.6668 10.6438 13.6668 10.8053V12.9668C13.6668 13.1668 13.5996 13.3335 13.465 13.4668C13.3304 13.6002 13.1622 13.6668 12.9603 13.6668ZM4.04883 6.21816L5.23866 5.07966C5.26 5.06255 5.27388 5.03905 5.28033 5.00916C5.28677 4.97927 5.28572 4.9515 5.27716 4.92583L4.98733 3.436C4.97877 3.40189 4.96383 3.37627 4.9425 3.35916C4.92116 3.34205 4.89338 3.3335 4.85916 3.3335H3.4335C3.40783 3.3335 3.38644 3.34205 3.36933 3.35916C3.35233 3.37627 3.34383 3.39766 3.34383 3.42333C3.37794 3.87889 3.4525 4.34166 3.5675 4.81166C3.68238 5.28177 3.84283 5.75061 4.04883 6.21816ZM9.84883 11.9797C10.2907 12.1857 10.7516 12.3432 11.2315 12.4522C11.7115 12.5611 12.16 12.6258 12.577 12.6463C12.6027 12.6463 12.6241 12.6378 12.6412 12.6207C12.6583 12.6036 12.6668 12.5822 12.6668 12.5565V11.154C12.6668 11.1198 12.6583 11.092 12.6412 11.0707C12.6241 11.0493 12.5984 11.0344 12.5643 11.0258L11.1643 10.7412C11.1387 10.7326 11.1162 10.7316 11.097 10.738C11.0778 10.7444 11.0574 10.7583 11.036 10.7797L9.84883 11.9797Z" />
    </svg>
  );
};

export default ICHotline;
