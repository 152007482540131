import { useEffect } from "react";
import ICChevronRight from "../../assets/icons/ICChevronRight";
import ButtonSolidMedium from "../../components/Button/ButtonSolidMedium";
import "./CheckoutPage.scss";

const CheckoutPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="checkout--page">
      <section className="checkout--page--category">
        <div className="checkout--page--category--container">
          <div className="checkout--page--category--container--content">
            <div className="checkout--page--category--container--content--title">
              <span className="checkout--page--category--container--content--title">
                Giỏ hàng trống
              </span>
            </div>
            <div className="checkout--page--category--container--content--description">
              <span className="checkout--page--category--container--content--description">
                Hãy lựa chọn sản phẩm
              </span>
            </div>
          </div>
          <ButtonSolidMedium
            title={"Xem tất cả sản phầm"}
            preIcon={<ICChevronRight width={24} height={24} />}
          />
        </div>
      </section>
    </div>
  );
};

export default CheckoutPage;
