const ICExpandMore = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: width ?? "100%", height: height ?? "auto" }}
      viewBox={`0 0 24 24`}
      fill="currentColor"
    >
      <path d="M12 15.375L6 9.37501L7.4 7.97501L12 12.575L16.6 7.97501L18 9.37501L12 15.375Z" />
    </svg>
  );
};
export default ICExpandMore;
