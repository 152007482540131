import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  electricSofa: [],
};

export const ElectricSofaReducer = createSlice({
  name: "electricSofa",
  initialState,
  reducers: {
    getElectricSofa: (state, action) => {
      state.electricSofa = action.payload;
    },
  },
});

export const { getElectricSofa } = ElectricSofaReducer.actions;

export default ElectricSofaReducer.reducer;
