import callApi from "../../utils/callApi";
import {
  getProductDetail,
  removeProductDetail,
} from "../reducers/productDetail.reducers";

export const getProductDetailAction = (prodId) => {
  return async (dispatch) => {
    try {
      const res = await callApi(`product/${prodId}`, "GET", null);

      await dispatch(getProductDetail(res.data.data));
    } catch (err) {
      console.log(err);
    }
  };
};

export const removeProductDetailAction = (prodId) => {
  return async (dispatch) => {
    await dispatch(removeProductDetail(prodId));
  };
};
