// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "./Swiper.scss";

import { Navigation,Virtual } from "swiper/modules";
import { SwiperNavButtons } from "./SwiperNavButtons";
import React, { useState } from "react";

const CustomSwiper = ({ swipers, allowTouchMove }) => {
  const [current , setCurrent] = useState(0);
  return (
    <Swiper
      modules={[Navigation, Virtual]}
      slidesPerView={1}
      allowTouchMove={allowTouchMove ?? true}
      lazy="true"
      virtual
      onSlideChange={(a) => setCurrent(a.activeIndex)}
    >
      <SwiperNavButtons
        isShow={swipers.length>0}
        // length={swipers.length-1}
        isFirst={current === 0}
        isLast={current === swipers.length - 1}
      />
      {swipers.map((swiper, i) => (
        <SwiperSlide key={i} virtualIndex={i}>
          {swiper}
          <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
        </SwiperSlide>
      ))}
      
    </Swiper>
  );
};

export default CustomSwiper;
