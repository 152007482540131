import "./Button.scss";

const ButtonSolidMedium = ({ title, type, preIcon, onClick }) => {
  return (
    <button className={`btn--solid medium ${preIcon??"pre--icon"}`} onClick={onClick} type={type}>
      {title}
      {preIcon}
    </button>
  );
};

export default ButtonSolidMedium;
