import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cartLocal: [],
};

export const CartLocalReducer = createSlice({
  name: "cartLocal",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const newProduct = action.payload;
      const cartItem = {
        product: newProduct,
        quantity: 1,
      };

      console.log(action.payload);

      // state.cartLocal.push(cartItem);

      const index = state.cartLocal.findIndex(
        (cart) => cart.product._id === newProduct._id
      );
      if (index === -1) {
        state.cartLocal.push(cartItem);
      } else {
        state.cartLocal[index].quantity = state.cartLocal[index].quantity + 1;
      }
    },

    increaseQuantity: (state, action) => {
      const productId = action.payload;
      const index = state.cart.findIndex(
        (cart) => cart.product._id === productId
      );

      state.cartLocal[index].quantity = state.cartLocal[index].quantity + 1;
    },

    decreaseQuantity: (state, action) => {
      const productId = action.payload;
      const index = state.cartLocal.findIndex(
        (cart) => cart.product._id === productId
      );

      if (state.cartLocal[index].quantity > 1) {
        state.cartLocal[index].quantity = state.cartLocal[index].quantity - 1;
      }
    },

    removeToCart: (state, action) => {
      const productId = action.payload;

      state.cartLocal.splice(
        state.cartLocal.findIndex((cart) => cart.product._id === productId),
        1
      );
    },
  },
});

export const { addToCart, increaseQuantity, decreaseQuantity, removeToCart } =
  CartLocalReducer.actions;

export default CartLocalReducer.reducer;
