export function formatToVND(amount) {
  try {
    return new Intl.NumberFormat("vi-VN", {
      style: "currency",
      currency: "VND",
    }).format(amount);
  } catch (e) {
    console.log(e);
    return "--";
  }
}
