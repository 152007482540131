import ICClose from "../../assets/icons/ICClose";
import { Divider } from "antd";
import ICNews from "../../assets/icons/ICNews";
import ICInfo from "../../assets/icons/ICInfo";
import IMG from "../../assets/images/example.jpeg";
import SOFA1 from "../../assets/images/sofa1.png";
import SOFA2 from "../../assets/images/sofa2.png";
import SOFA3 from "../../assets/images/sofa3.png";
import SOFA4 from "../../assets/images/sofa4.png";
import VIRTUAL from "../../assets/images/virtual.png";
import ICLocation from "../../assets/icons/ICLocation";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ButtonIconLarge from "../Button/ButtonIconLarge";
import ButtonSmall from "../Button/ButtonSmall";
import ICExpandMore from "../../assets/icons/ICExpandMore";
import { useDispatch, useSelector } from "react-redux";
import { getDataCategoryFilter } from "../../store/actions/productCategory.action";

const TAB = [
  {
    id: 1,
    title: "sofa",
    img: IMG,
    url:"product-category"
  },
  {
    id: 2,
    title: "virtual",
    img: VIRTUAL,
    url:"virtual"
  },
];

const MenuComponent = () => {
  const dispatch = useDispatch();

  const { dataProductCategory } = useSelector(
    (state) => state.dataProductCategory
  );

  const [hoverImage, setHoverImage] = useState();
  //   const [shrink, setShrink] = useState(true);
  const [currentTab, setCurrentTab] = useState(1);

  const navigate = useNavigate();

  const location = useLocation();
  const currentUrl = location.pathname;

  const [searchParams, ] = useSearchParams();
  const _id = searchParams.get("_id");

  const closeMenu = () => {
    const menu = document.getElementById("menu--header");
    if (menu) {
      menu.classList.add("shrink");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    // dispatch(getDataProduct(null, null, null, null, null));
    dispatch(getDataCategoryFilter());
  }, []);

  return (
    <div className={`menu shrink`} id="menu--header">
      <div className="menu--title">
        <span>Danh mục</span>
        <ButtonIconLarge
          icon={<ICClose />}
          onClick={() => {
            closeMenu();
          }}
        />
      </div>
      <div className="menu--body">
        <div className="category">
          <div className="menu--main">
            <div className="menu--main--header">
              {TAB.map((tab, i) => (
                <div
                  className="menu--item"
                  key={i}
                  onClick={() => {
                    setCurrentTab(tab.id);
                    setHoverImage(tab.img);
                  }}
                >
                  <div className={`menu--item--content`}>
                    <span
                      className={`menu--item--content-name hover-underline-animation ${(currentUrl?.includes(tab.url) || currentTab===tab.id)&&"active"}`}
                    >
                      {tab.title}
                    </span>
                  </div>
                </div>
              ))}
            </div>
            <Divider
              type="vertical"
              style={{
                height: "100%",
                backgroundColor: "rgba(29, 29, 31, 0.16)",
                width: "1px",
                margin: "0px",
                borderInlineStart: "none",
              }}
            />
            <div className={`menu--main--content`}>
              <div
                className={`sub  ${
                  currentTab === 1 || currentTab === undefined ? "active" : ""
                }`}
              >
                {dataProductCategory?.categoryFilter?.map((category) => (
                  <div
                    className="menu--item"
                    key={category?._id}
                    onMouseOver={() => setHoverImage(category?.icon)}
                    // onMouseLeave={() => setHoverImage(undefined)}
                    onClick={() => {
                      closeMenu();
                      navigate(`/product-category?_id=${category?._id}`);
                    }}
                  >
                    <div className={`menu--item--content`}>
                      <span className={`menu--item--content-name hover-underline-animation  ${_id===category._id && "active"}`}>
                        {category?.title}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
              <div
                className={`other--sub  ${currentTab === 2 ? "active" : ""}`}
              >
                <div className="sub--content">
                  <div className="sub--content-title">
                    <span>Tăng cường trải nghiệm</span>
                  </div>
                  <div className="sub--content-subtitle">
                    <span>
                      Ứng dụng độc quyền trải nghiệm bố trí nội thất không gian
                      3D siêu thực
                    </span>
                  </div>
                </div>
                {/* <div className="menu--item">
                  <div className="menu--item--content">
                    <div className="menu--item--content-name hover-underline-animation">
                      <span>Trải nghiệm ngay</span>
                    </div>
                  </div>
                </div> */}
                <ButtonSmall title={"Trải nghiệm ngay"} onClick={(e)=>{
                e.stopPropagation();
                closeMenu();
                window.open("https://designv2.lanha.vn/")
                }} type={"button"}/>
              </div>
              {currentTab === 1 || currentTab === undefined ? (
                <div className="block">
                  <img src={hoverImage ?? IMG} alt="" />
                </div>
              ) : (
                <div className="sub--block">
                  <img src={hoverImage ?? IMG} alt="" />
                </div>
              )}
            </div>
            {/* <div
              className={`menu--main-content ${
                currentTab === 2 ? "active" : ""
              }`}
            >
              <div className="block">
                <img src={hoverImage ?? IMG} alt="" />
              </div>
            </div> */}
          </div>
          <div className="menu--mobile">
            <div
              className="menu--item"
              onClick={() => {
                if (currentTab !== 1) {
                  setCurrentTab(1);
                  setHoverImage(IMG);
                } else {
                  setCurrentTab(undefined);
                  setHoverImage(undefined);
                }
              }}
            >
              <div className={`menu--item--content`}>
                <span
                  className={`menu--item--content-name hover-underline-animation `}
                >
                  sofa
                </span>
              </div>
              <ICExpandMore width={24} height={24} />
            </div>
            <div
              className={`menu--mobile--content ${
                currentTab === 1 ? "active" : ""
              }`}
            >
              <div className={`sub`}>
                {dataProductCategory?.categoryFilter?.map((category) => (
                  <div
                    className="menu--item"
                    key={category?._id}
                    onMouseOver={() => setHoverImage(category?.icon)}
                    onMouseLeave={() => setHoverImage(undefined)}
                    onClick={() => {
                      closeMenu();
                      navigate(`/product-category?_id=${category?._id}`);
                    }}
                  >
                    <div className="menu--item--content">
                      <span className="menu--item--content-name hover-underline-animation">
                        {category?.title}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
              <div className="block">
                <img src={hoverImage ?? IMG} alt="" />
              </div>
            </div>
          </div>
          <div className="menu--mobile">
            <div
              className="menu--item"
              onClick={() => {
                if (currentTab !== 2) {
                  setCurrentTab(2);
                  setHoverImage(VIRTUAL);
                } else {
                  setCurrentTab(undefined);
                  setHoverImage(undefined);
                }
              }}
            >
              <div className="menu--item--content">
                <span
                  className={`menu--item--content-name hover-underline-animation `}
                >
                  virtual
                </span>
              </div>
              <ICExpandMore width={24} height={24} />
            </div>
            <div
              className={`menu--mobile--content ${
                currentTab === 2 ? "active" : ""
              }`}
            >
              <div className={`other--sub`}>
                <div className="other--sub--content">
                  <div className="other--sub--content-title">
                    <span>Tăng cường trải nghiệm</span>
                  </div>
                  <div className="other--sub--content-subtitle">
                    <span>
                      Ứng dụng độc quyền trải nghiệm bố trí nội thất không gian
                      3D siêu thực
                    </span>
                  </div>
                </div>
                <ButtonSmall title={"Trải nghiệm ngay"} />
              </div>
              <div className="block">
                <img src={VIRTUAL} alt="" />
              </div>
            </div>
          </div>
        </div>
        <Divider
          style={{ backgroundColor: "rgba(29, 29, 31, 0.16)", margin: "0" }}
        />
        <div className="block--sub">
          <div className="button">
            <ICNews width={16} height={16} />
            <ButtonSmall title={"Tin tức"} />
          </div>
          <div className="button">
            <ICInfo width={16} height={16} />
            <ButtonSmall title={"Về chúng tôi"} />
          </div>
          <div className="button">
            <ICLocation width={16} height={16} />
            <ButtonSmall title={"Cửa hàng"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuComponent;
