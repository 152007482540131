const ICBookmark = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="currentColor"
    >
      <path d="M4.125 15.1875V3.98081C4.125 3.60194 4.25625 3.28125 4.51875 3.01875C4.78125 2.75625 5.10194 2.625 5.48081 2.625H12.5192C12.8981 2.625 13.2188 2.75625 13.4813 3.01875C13.7438 3.28125 13.875 3.60194 13.875 3.98081V15.1875L9 13.0961L4.125 15.1875ZM5.25 13.4625L9 11.85L12.75 13.4625V3.98081C12.75 3.92306 12.7259 3.87019 12.6778 3.82219C12.6298 3.77406 12.5769 3.75 12.5192 3.75H5.48081C5.42306 3.75 5.37019 3.77406 5.32219 3.82219C5.27406 3.87019 5.25 3.92306 5.25 3.98081V13.4625Z" />
    </svg>
  );
};
export default ICBookmark;
