import { useSelector } from "react-redux";
import ICAMEX from "../../assets/icons/ICAMEX";
import ICFacebook from "../../assets/icons/ICFacebook";
import ICHotline from "../../assets/icons/ICHotline";
import ICInstagram from "../../assets/icons/ICInstagram";
import ICJCB from "../../assets/icons/ICJCB";
import ICLocation from "../../assets/icons/ICLocation";
import ICMail from "../../assets/icons/ICMail";
import ICMaster from "../../assets/icons/ICMaster";
import ICVisa from "../../assets/icons/ICVisa";
import ButtonOutlineSmall from "../Button/ButtonOutlineSmall";
import TextFiled from "../Input/TextFiled";

import "./Footer.scss";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const { dataProductCategory } = useSelector(
    (state) => state.dataProductCategory
  );

  const navigate = useNavigate();

  return (
    <footer>
      <div className="footer">
        <div className="footer--info">
          <div className="footer--info-title">
            <span>thông tin</span>
          </div>
          <div className="footer--info--content">
            <div className="footer--info--content-value">
              <ICHotline width={16} height={16} />
              <span>0941 446 571</span>
              <span>T2-T7, 8:00SA-18:30CH</span>
            </div>
            <div className="footer--info--content-value">
              <ICLocation width={16} height={16} />
              <span>55 Đinh Thị Thi (KDC Vạn Phúc), QL13, Thủ Đức, HCM</span>
            </div>
            <div className="footer--info--content-value">
              <ICLocation width={16} height={16} />
              <span>KDC Trung Sơn, Bình Chánh</span>
            </div>
            <div className="footer--info--content-value">
              <ICMail width={16} height={16} />
              <span>info.lanha@gmail.com</span>
            </div>
          </div>
        </div>
        <div className="footer--orther">
          <div className="footer--orther-title">
            <span>sản phẩm</span>
          </div>
          <div className="footer--orther--content">
            {dataProductCategory?.categoryFilter?.map((category) => (
              <div
                key={category?._id}
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/product-category?_id=${category?._id}`);
                }}
                className="footer--orther--content-name hover-underline-animation"
                style={{ textTransform: "uppercase" }}
              >
                <span>{category?.title}</span>
              </div>
            ))}
          </div>
        </div>
        <div className="footer--orther">
          <div className="footer--orther-title">
            <span>hỗ trợ</span>
          </div>
          <div className="footer--orther--content">
            <div className="footer--orther--content-name hover-underline-animation">
              <span>Điều khoản & điều kiện</span>
            </div>
            <div className="footer--orther--content-name hover-underline-animation">
              <span>Chính sách bảo hành</span>
            </div>
            <div className="footer--orther--content-name hover-underline-animation">
              <span>Chính sách đổi trả</span>
            </div>
            <div className="footer--orther--content-name hover-underline-animation">
              <span>Chính sách giao hàng</span>
            </div>
          </div>
        </div>
        <div className="footer--social">
          <div className="footer--social-title">
            <span>theo dõi chúng tôi</span>
          </div>
          <div className="footer--social--content">
            <div className="footer--social--content--container">
              <ICFacebook width={32} height={32} />

              <div className="footer--social--content--container-name">
                <span>Facebook</span>
              </div>
            </div>
            <div className="footer--social--content--container">
              <ICInstagram width={32} height={32} />
              <div className="footer--social--content--container-name">
                <span>Instagram</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="footer--left">
          <div className="footer--left-name">Là Nhà Decor</div>
        </div>
        <div className="footer--right">
          <div className="footer--right-title">
            ĐĂNG KÝ NHẬN THÔNG TIN ƯU ĐÃI
          </div>
          <div className="footer--right-content">
            <TextFiled placeholder={"@gmail.com"} />
            <ButtonOutlineSmall title={"Đăng ký"} style={{ flex: "none" }} />
          </div>
        </div>
      </div>
      <div className="copyright">
        <div className="copyright--content">
          <span>Copyright © 2024 Là Nhà Decor. All Rights Reserved</span>
        </div>
        <div className="copyright--bank">
          <ICVisa />
          <ICMaster />
          <ICJCB />
          <ICAMEX />
        </div>
      </div>
    </footer>
  );
};
export default Footer;
