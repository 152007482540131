const ICClose = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // width={width}
      // height={height}
      style={{ width: width ?? "100%", height: height ?? "auto" }}
      viewBox="0 0 32 32"
      fill="currentColor"
    >
      <path d="M8.53317 25.3334L6.6665 23.4667L14.1332 16.0001L6.6665 8.53341L8.53317 6.66675L15.9998 14.1334L23.4665 6.66675L25.3332 8.53341L17.8665 16.0001L25.3332 23.4667L23.4665 25.3334L15.9998 17.8667L8.53317 25.3334Z" />
    </svg>
  );
};

export default ICClose;
