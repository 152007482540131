const ICMenu = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: width ?? "100%", height: height ?? "auto" }}
      viewBox={`0 0 32 32`}
      fill="currentColor"
    >
      <path d="M4.66699 23.5129V21.5132H27.3337V23.5129H4.66699ZM4.66699 17.0002V15.0002H27.3337V17.0002H4.66699ZM4.66699 10.4872V8.48755H27.3337V10.4872H4.66699Z" />
    </svg>
  );
};
export default ICMenu;
