import { useSwiper } from "swiper/react";
import ICChevronLeft from "../../assets/icons/ICChevronLeft";
import ICChevronRight from "../../assets/icons/ICChevronRight";
import ButtonIconSolidMedium from "../Button/ButtonIconSolidMedium";

export const SwiperNavButtons = ({ isFirst, isLast, isShow }) => {
  const swiper = useSwiper();

  return (
    <>
      {isShow && (
        <>
          <div
            className={`custom-prev-button ${
              isFirst && "swiper-button-disabled"
            }`}
            onClick={(e) => {
              e.stopPropagation();
              swiper.slidePrev()
            }}
          >
            <ButtonIconSolidMedium
              icon={<ICChevronLeft width={20} height={20} />}
              disabled={isFirst}
              type={"button"}
            />
          </div>
          <div
            className={`custom-next-button ${
              isLast && "swiper-button-disabled"
            }`}
            onClick={(e) => {
              e.stopPropagation();
              swiper.slideNext()
            }}
          >
            <ButtonIconSolidMedium
              icon={<ICChevronRight width={20} height={20} disabled={isLast} 
              type={"button"}
              />}
            />
          </div>
        </>
      )}
    </>
  );
};
