import { useEffect, useState } from "react";
import ButtonOutlineSmall from "../../components/Button/ButtonOutlineSmall";
import ButtonSolidSmall from "../../components/Button/ButtonSolidSmall";
import ButtonDropdown from "../../components/ButtonDropdown/ButtonDropdown";
import ChipSuggest32 from "../../components/Chip/ChipSuggest32";
import InputDualRange from "../../components/Input/InputDualRange";
import { formatToVND } from "../../utils/currency";

const ButtonDropdownPrice = ({
  id,
  fromFilter,
  toFilter,
  minStep,
  minRange,
  maxRange,
  minValue,
  maxValue,
  setFromFilter,
  setToFilter,
  showButton,
}) => {
  const [tempFrom, setTempFrom] = useState();
  const [tempTo, setTempTo] = useState();

  const closeModal = () => {
    const dropdown = document.getElementById(id + "Dropdown-Container");

    if (dropdown) {
      dropdown.classList.remove("show");
      dropdown.classList.add("hidden");
    }
  };

  useEffect(() => {
    setTempFrom(fromFilter);
    setTempTo(toFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromFilter, toFilter]);

  return (
    <ButtonDropdown
      isHidden={true}
      title={"Giá tiền"}
      badge={fromFilter !== null && toFilter !== null ? "1" : undefined}
      id={id}
      container={
        <div className="price--filter--container">
          <div className="price--filter--container-content">
            <InputDualRange
              id={id + "RangePrice"}
              minStep={minStep}
              minRange={minRange}
              maxRange={maxRange}
              minValue={(showButton ? tempFrom : minValue) ?? minRange}
              maxValue={(showButton ? tempTo : maxValue) ?? maxRange}
              setMinValue={showButton ? setTempFrom : setFromFilter}
              setMaxValue={showButton ? setTempTo : setToFilter}
              minLabel={formatToVND(
                showButton ? tempFrom ?? minRange : minValue
              )}
              maxLabel={formatToVND(showButton ? tempTo ?? maxRange : maxValue)}
            />
            <div className="container--label">
              <ChipSuggest32
                isSelected={
                  showButton
                    ? tempFrom === "0" && tempTo === "5000000"
                    : minValue === "0" && maxValue === "5000000"
                }
                title={"Dưới 5 triệu"}
                onClick={() => {
                  if (showButton) {
                    setTempFrom(0);
                    setTempTo(5000000);
                  } else {
                    setFromFilter(0);
                    setToFilter(5000000);
                  }
                }}
              />
              <ChipSuggest32
                isSelected={
                  showButton
                    ? tempFrom === "5000000" && tempTo === "10000000"
                    : minValue === "5000000" && maxValue === "10000000"
                }
                title={"5 đến 10 triệu"}
                onClick={() => {
                  if (showButton) {
                    setTempFrom(5000000);
                    setTempTo(10000000);
                  } else {
                    setFromFilter(5000000);
                    setToFilter(10000000);
                  }
                }}
              />
              <ChipSuggest32
                isSelected={
                  showButton
                    ? tempFrom === "10000000" && tempTo === "15000000"
                    : minValue === "10000000" && maxValue === "15000000"
                }
                title={"10 đến 15 triệu"}
                onClick={() => {
                  if (showButton) {
                    setTempFrom(10000000);
                    setTempTo(15000000);
                  } else {
                    setFromFilter(10000000);
                    setToFilter(15000000);
                  }
                }}
              />
              <ChipSuggest32
                isSelected={
                  showButton
                    ? tempFrom === "15000000" && tempTo === "20000000"
                    : minValue === "15000000" && maxValue === "20000000"
                }
                title={"15 đến 20 triệu"}
                onClick={() => {
                  if (showButton) {
                    setTempFrom(15000000);
                    setTempTo(20000000);
                  } else {
                    setFromFilter(15000000);
                    setToFilter(20000000);
                  }
                }}
              />
              <ChipSuggest32
                isSelected={
                  showButton
                    ? tempFrom === "20000000" && tempTo === "30000000"
                    : minValue === "20000000" && maxValue === "30000000"
                }
                title={"20 đến 30 triệu"}
                onClick={() => {
                  if (showButton) {
                    setTempFrom(20000000);
                    setTempTo(30000000);
                  } else {
                    setFromFilter(20000000);
                    setToFilter(30000000);
                  }
                }}
              />
            </div>
          </div>
          {showButton && (
            <div className="price--filter--container-button">
              <ButtonOutlineSmall
                title={"Đóng"}
                type={"button"}
                onClick={() => {
                  closeModal();
                  setFromFilter(undefined);
                  setToFilter(undefined);
                }}
              />
              <ButtonSolidSmall
                title={"Áp dụng"}
                type={"button"}
                onClick={() => {
                  closeModal();
                  setFromFilter(tempFrom);
                  setToFilter(tempTo);
                }}
              />
            </div>
          )}
        </div>
      }
    />
  );
};

export default ButtonDropdownPrice;
