import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dataHomePage: {},
};

export const HomePageReducer = createSlice({
  name: "dataHomePage",
  initialState,
  reducers: {
    setDataHomePage: (state, action) => {
      const section = action.payload.section;

      if (
        !state.dataHomePage[section] ||
        state.dataHomePage[section].length === 0
      ) {
        state.dataHomePage[section] = action.payload;
      }
    },
  },
});

export const { setDataHomePage } = HomePageReducer.actions;

export default HomePageReducer.reducer;
