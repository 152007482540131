import "./Button.scss";

const ButtonSolidSmall = ({ title, type, onClick }) => {
  return (
    <button className="btn--solid small" type={type} onClick={onClick}>
      {title}
    </button>
  );
};

export default ButtonSolidSmall;
