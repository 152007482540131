import React from "react";
import { useNavigate } from "react-router-dom";
import ICChevronRight from "../../assets/icons/ICChevronRight";
import "./CardViewMore.scss";

export default function CardViewMore({ data, categoryId }) {
  const navigate = useNavigate();

  return (
    <main className="cardViewMore">
      <img className="cardViewMore__image" src={data?.attachments[0]} alt="" />

      <div className="cardViewMore__content">
        <span className="cardViewMore__content__type">{data?.title}</span>

        <button
          className="btn-white"
          onClick={() => navigate(`/product-category?_id=${categoryId}`)}
        >
          <span
            dangerouslySetInnerHTML={{
              __html: data?.content_button,
            }}
          />
          <ICChevronRight width={18} height={18} />
        </button>
      </div>
    </main>
  );
}
