const ICDropdown = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="currentColor"
    >
      <path d="M8.00004 9.76933L5.06421 6.8335H10.9359L8.00004 9.76933Z" />
    </svg>
  );
};
export default ICDropdown;
