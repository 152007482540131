const ICMail = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="currentColor"
    >
      <path d="M2.87167 13C2.53489 13 2.24984 12.8833 2.0165 12.65C1.78317 12.4167 1.6665 12.1316 1.6665 11.7948V4.20517C1.6665 3.86839 1.78317 3.58333 2.0165 3.35C2.24984 3.11667 2.53489 3 2.87167 3H13.128C13.4648 3 13.7498 3.11667 13.9832 3.35C14.2165 3.58333 14.3332 3.86839 14.3332 4.20517V11.7948C14.3332 12.1316 14.2165 12.4167 13.9832 12.65C13.7498 12.8833 13.4648 13 13.128 13H2.87167ZM7.99984 8.37183L2.6665 4.9615V11.7948C2.6665 11.8547 2.68573 11.9039 2.72417 11.9423C2.76262 11.9808 2.81178 12 2.87167 12H13.128C13.1879 12 13.2371 11.9808 13.2755 11.9423C13.3139 11.9039 13.3332 11.8547 13.3332 11.7948V4.9615L7.99984 8.37183ZM7.99984 7.33333L13.2307 4H2.769L7.99984 7.33333ZM2.6665 4.9615V4V11.7948C2.6665 11.8547 2.68573 11.9039 2.72417 11.9423C2.76262 11.9808 2.81178 12 2.87167 12H2.6665V4.9615Z" />
    </svg>
  );
};

export default ICMail;
