import { useState } from "react";
import ICChevronLeft from "../../assets/icons/ICChevronLeft";
import ICChevronRight from "../../assets/icons/ICChevronRight";

import "./Pagination.scss";

const PaginationBasic = ({ total, limit, setLimit, offset, setOffset, id }) => {
  return (
    <section className="pagination">
      <button
        className={`pagination--button prev`}
        disabled={offset === 0}
        onClick={(e) => {
          e.preventDefault();
          // setCurr(curr - 1);
          setOffset(offset - limit);
        }}
      >
        <ICChevronLeft width={20} height={20} />
      </button>
      {total <= 5 ? (
        Array.from({ length: total }, (_, i) => i + 1).map((i, j) => (
          <button
            className={`pagination--button num ${
              (offset + limit) / limit === i ? "active" : ""
            }`}
            onClick={(e) => {
              e.preventDefault();
              setOffset(i * limit - limit);
            }}
            key={j}
          >
            {i}
          </button>
        ))
      ) : (
        <>
          {(offset + limit) / limit - 3 > 1 ? (
            <button
              className="pagination--button num"
              onClick={(e) => {
                e.preventDefault();
                setOffset(0);
              }}
            >
              1
            </button>
          ) : (
            <></>
          )}
          {(offset + limit) / limit > 3 ? (
            <button className="btn--elipsis">...</button>
          ) : (
            <></>
          )}
          {(offset + limit) / limit <= 3
            ? Array.from({ length: 5 }, (_, i) => i + 1).map((i, j) => (
                <button
                  className={`pagination--button num ${
                    (offset + limit) / limit === i ? "active" : ""
                  }`}
                  onClick={(e) => {
                    e.preventDefault();
                    setOffset(limit * i - limit);
                  }}
                  key={j}
                >
                  {i}
                </button>
              ))
            : (offset + limit) / limit > total - 3
            ? Array.from({ length: 5 }, (_, i) => i + total - 4).map((i, j) => (
                <button
                  className={`pagination--button num ${
                    (offset + limit) / limit === i ? "active" : ""
                  }`}
                  onClick={(e) => {
                    e.preventDefault();
                    setOffset(limit * i - limit);
                  }}
                  key={j}
                >
                  {i}
                </button>
              ))
            : Array.from({ length: 5 }, (_, i) => i + offset / limit - 2).map(
                (i, j) => (
                  <button
                    className={`pagination--button num ${
                      (offset + limit) / limit === i ? "active" : ""
                    }`}
                    key={j}
                    onClick={(e) => {
                      e.preventDefault();
                      setOffset(i * limit - limit);
                    }}
                  >
                    {i}
                  </button>
                )
              )}
          {(offset + limit) / limit < total - 2 ? (
            <button className="btn--elipsis">...</button>
          ) : (
            <></>
          )}
          {(offset + limit) / limit + 2 < total ? (
            <button
              className="pagination--button num "
              onClick={(e) => {
                e.preventDefault();
                setOffset(total * limit - limit);
              }}
            >
              {total}
            </button>
          ) : (
            <></>
          )}
        </>
      )}
      <button
        className={`pagination--button next`}
        disabled={
          (offset + limit) / limit === total || (offset + limit) / limit === 1
        }
        onClick={(e) => {
          e.preventDefault();
          setOffset(offset + limit);
        }}
      >
        <ICChevronRight width={20} height={20} />
      </button>
    </section>
  );
};

export default PaginationBasic;
