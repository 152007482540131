import React, { useEffect, useRef, useState } from "react";
import "./ModalCart.scss";
import { useSelector } from "react-redux";

export default function ModalCart({ close }) {
  const { cartLocal } = useSelector((state) => state.cartLocal);

  return (
    <main className="modal-cart">
      <div className="modal-cart__close" onClick={close}></div>

      <div className="modal-cart__main">
        <div className="modal-cart__main__header">
          <h4 className="modal-cart__main__header__title">
            Đã thêm vào giỏ hàng
          </h4>

          <button
            className="modal-cart__main__header__close"
            onClick={() => {
              close();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M6.39994 18.6542L5.34619 17.6004L10.9462 12.0004L5.34619 6.40043L6.39994 5.34668L11.9999 10.9467L17.5999 5.34668L18.6537 6.40043L13.0537 12.0004L18.6537 17.6004L17.5999 18.6542L11.9999 13.0542L6.39994 18.6542Z"
                fill="#121212"
              />
            </svg>
          </button>
        </div>

        <div className="modal-cart__main__body">
          <div className="modal-cart__main__body__list-card">
            {cartLocal?.map((cItem) => {
              return (
                <div key={cItem._id} className="card-cart">
                  <img className="card-cart__image" src="" alt="" />

                  <div className="card-cart__info">
                    <h5 className="card-cart__info__name">
                      {cItem?.product?._id}
                    </h5>
                    <p className="card-cart__info__detail">{cItem?.quantity}</p>

                    <p className="card-cart__info__detail">Vật liệu: </p>
                    <p className="card-cart__info__detail">Bố trí: </p>

                    <div className="d-flex flex-row" style={{ gap: 8 }}>
                      <span className="card-cart__info__price-org">
                        10,800,000 đ
                      </span>
                      <span className="card-cart__info__price-sale">
                        9,600,000 đ
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="modal-cart__main__footer col-12">
          <button className="modal-cart__main__footer__submit">
            Tiến hành đặt hàng
          </button>
        </div>
      </div>
    </main>
  );
}
