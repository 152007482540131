import callApi from "../../utils/callApi";
import { setDataHomePage } from "../reducers/homePage.reducers";

export const getDataSection = (path) => {
  return async (dispatch) => {
    try {
      const res = await callApi(path, "GET", null);

      await dispatch(setDataHomePage(res.data.data));
    } catch (err) {
      console.log(err);
    }
  };
};
