import "./Tag.scss";

const TagPrice = ({ title, absoluteTopLeft }) => {
  return (
    <div className={`tag--price ${absoluteTopLeft ? "absolute--top-left" : ""}`}>
      <span>{title}</span>
    </div>
  );
};

export default TagPrice;
