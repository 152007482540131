import { createAsyncThunk } from "@reduxjs/toolkit";
import callApi from "../../utils/callApi";
import { FEATURES, Product, TAGS } from "../../utils/enum";
import {
  setCategoryFilter,
  setSimilarColorFilter,
  setMaterialFilter,
  setStyleFilter,
  setDesignFilter,
} from "../reducers/productCategory.reducers";

// export const getDataProduct = (
//   category,
//   fromPrice,
//   toPrice,
//   color,
//   material,
//   limit,
//   offset,
//   name
// ) => {
//   return async (dispatch) => {
//     try {
//       const res = await callApi(Product.PRODUCTS, "GET", null, null, {
//         category: category,
//         from_price: fromPrice,
//         to_price: toPrice,
//         color: color,
//         material: material,
//         limit: limit,
//         offset: offset,
//         name: name,
//       });

//       await dispatch(setTotalProducts(res?.data?.count));
//       await dispatch(setProducts(res?.data?.data));
//     } catch (err) {
//       console.log(err);
//     }
//   };
// };

export const getDataProduct = createAsyncThunk(
  "dataProductCategory/fetchProducts",
  async ({
    category,
    fromPrice,
    toPrice,
    colors,
    materials,
    limit,
    offset,
    name,
    sort,
    tag,
  }, thunkAPI) => {
    const params = new URLSearchParams();

    if (category) params.append("category", category);
    if (fromPrice) params.append("from_price", fromPrice);
    if (toPrice) params.append("to_price", toPrice);
    if (limit) params.append("limit", limit);
    if (sort) params.append("sort", sort);
    params.append("offset", offset ?? 0);
    if (name) params.append("name", name);
    colors?.forEach((id) => {
      if (id) {
        params.append("color", id);
      }
    });
    materials?.forEach((id) => {
      if (id) {
        params.append("material", id);
      }
    });
    tag?.forEach((id) => {
      if (id) {
        params.append("tag", id);
      }
    });
    const res = await callApi(Product.PRODUCTS, "GET", null, null, params);
    return res?.data;
  }
);

export const getDataCategoryFilter = () => {
  return async (dispatch) => {
    try {
      const res = await callApi(Product.CATEGORIES, "GET", null);

      await dispatch(setCategoryFilter(res?.data?.data));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getDataSimilarColorFilter = () => {
  return async (dispatch) => {
    try {
      const res = await callApi(Product.FEATURES, "GET", null, null, {
        type: FEATURES.SIMILAR_COLOR,
      });

      await dispatch(setSimilarColorFilter(res?.data?.data));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getDataMaterialFilter = () => {
  return async (dispatch) => {
    try {
      const res = await callApi(Product.FEATURES, "GET", null, null, {
        type: FEATURES.MATERIAL,
      });

      await dispatch(setMaterialFilter(res?.data?.data));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getDataStyleFilter = () => {
  return async (dispatch) => {
    try {
      const res = await callApi(Product.TAGS, "GET", null, null, {
        type: TAGS.STYLE,
      });

      await dispatch(setStyleFilter(res?.data?.data));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getDataShapeFilter = () => {
  return async (dispatch) => {
    try {
      const res = await callApi(Product.TAGS, "GET", null, null, {
        type: TAGS.DESIGN,
      });

      await dispatch(setDesignFilter(res?.data?.data));
    } catch (err) {
      console.log(err);
    }
  };
};
