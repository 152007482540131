import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import "./MainLayout.scss";

export default function MainLayout(props) {
  return (
    <>
      <main className="main">
        <Header />
        <div className="body">{props.children}</div>
        <Footer />
      </main>
    </>
  );
}
