const ICError = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="currentColor"
    >
      <path d="M7.99984 11.1542C8.15239 11.1542 8.28028 11.1025 8.3835 10.9993C8.48673 10.8961 8.53834 10.7682 8.53834 10.6157C8.53834 10.4631 8.48673 10.3352 8.3835 10.232C8.28028 10.1289 8.15239 10.0773 7.99984 10.0773C7.84728 10.0773 7.71939 10.1289 7.61617 10.232C7.51295 10.3352 7.46134 10.4631 7.46134 10.6157C7.46134 10.7682 7.51295 10.8961 7.61617 10.9993C7.71939 11.1025 7.84728 11.1542 7.99984 11.1542ZM7.49984 8.71832H8.49984V4.71833H7.49984V8.71832ZM8.001 14.3337C7.125 14.3337 6.30161 14.1674 5.53084 13.835C4.76006 13.5025 4.08961 13.0514 3.5195 12.4815C2.94939 11.9116 2.498 11.2414 2.16534 10.471C1.83278 9.70055 1.6665 8.87738 1.6665 8.00149C1.6665 7.12549 1.83273 6.3021 2.16517 5.53133C2.49761 4.76055 2.94878 4.0901 3.51867 3.51999C4.08856 2.94988 4.75873 2.49849 5.52917 2.16583C6.29961 1.83327 7.12278 1.66699 7.99867 1.66699C8.87467 1.66699 9.69806 1.83321 10.4688 2.16566C11.2396 2.4981 11.9101 2.94927 12.4802 3.51916C13.0503 4.08905 13.5017 4.75921 13.8343 5.52966C14.1669 6.3001 14.3332 7.12327 14.3332 7.99916C14.3332 8.87516 14.1669 9.69855 13.8345 10.4693C13.5021 11.2401 13.0509 11.9105 12.481 12.4807C11.9111 13.0508 11.2409 13.5022 10.4705 13.8348C9.70006 14.1674 8.87689 14.3337 8.001 14.3337ZM7.99984 13.3337C9.48873 13.3337 10.7498 12.817 11.7832 11.7837C12.8165 10.7503 13.3332 9.48921 13.3332 8.00033C13.3332 6.51144 12.8165 5.25033 11.7832 4.21699C10.7498 3.18366 9.48873 2.66699 7.99984 2.66699C6.51095 2.66699 5.24984 3.18366 4.2165 4.21699C3.18317 5.25033 2.6665 6.51144 2.6665 8.00033C2.6665 9.48921 3.18317 10.7503 4.2165 11.7837C5.24984 12.817 6.51095 13.3337 7.99984 13.3337Z" />
    </svg>
  );
};

export default ICError;
