import callApi from "../../utils/callApi";
import {
  getFeatureDetail,
  removeFeatureDetail,
} from "../reducers/featureDetail.reducers";

export const getFeatureDetailAction = (lstFeatureId, prodId) => {
  return async (dispatch) => {
    const queryString = lstFeatureId?.map((id) => `&feature=${id}`).join("");

    try {
      const res = await callApi(
        `product-info?product=${prodId}${queryString}`,
        "GET",
        null
      );

      await dispatch(getFeatureDetail(res.data.data));
    } catch (err) {
      console.log(err);
    }
  };
};

export const removeFeatureDetailAction = (prodId) => {
  return async (dispatch) => {
    await dispatch(removeFeatureDetail(prodId));
  };
};
