const ICNews = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="currentColor"
    >
      <path d="M2.70517 13.8335C2.37372 13.8335 2.09 13.7155 1.854 13.4795C1.618 13.2435 1.5 12.9598 1.5 12.6283V2.40397L2.45 3.35397L3.55 2.2373L4.66667 3.35397L5.78333 2.2373L6.88333 3.35397L8 2.2373L9.11667 3.35397L10.2167 2.2373L11.3333 3.35397L12.45 2.2373L13.55 3.35397L14.5 2.40397V12.6283C14.5 12.9598 14.382 13.2435 14.146 13.4795C13.91 13.7155 13.6263 13.8335 13.2948 13.8335H2.70517ZM2.70517 12.8335H7.5V8.50014H2.5V12.6283C2.5 12.6882 2.51922 12.7374 2.55767 12.7758C2.59611 12.8142 2.64528 12.8335 2.70517 12.8335ZM8.5 12.8335H13.2948C13.3547 12.8335 13.4039 12.8142 13.4423 12.7758C13.4808 12.7374 13.5 12.6882 13.5 12.6283V11.1668H8.5V12.8335ZM8.5 10.1668H13.5V8.50014H8.5V10.1668ZM2.5 7.50014H13.5V5.10264H2.5V7.50014Z" />
    </svg>
  );
};

export default ICNews;
