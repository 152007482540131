const ICSort = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="currentColor"
    >
      <path d="M2.33325 11.6666V10.6666H5.91658V11.6666H2.33325ZM2.33325 8.49992V7.49992H9.78842V8.49992H2.33325ZM2.33325 5.33325V4.33325H13.6666V5.33325H2.33325Z" />
    </svg>
  );
};
export default ICSort;
