import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ProductCard from "../../components/ProductCard/ProductCard";
import "./HomePage.scss";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { FreeMode, Navigation, Pagination, Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { banners, keyDetails, lstProd } from "../../utils/data";
import CardViewMore from "../../components/CardViewMore/CardViewMore";
import CardExpand from "../../components/CardExpand/CardExpand";
import ICChevronRight from "../../assets/icons/ICChevronRight";
import { useDispatch, useSelector } from "react-redux";
import { CategoryId, ProductPath, SectionPath } from "../../utils/enum";
import { getDataSection } from "../../store/actions/homePage.action";
import { getElectricSofaAction } from "../../store/actions/electricSofa.action";
import { getMechanicalSofaAction } from "../../store/actions/mechanicalSofa.action";

export default function HomePage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { dataHomePage } = useSelector((state) => state.dataHomePage);
  const { electricSofa } = useSelector((state) => state.electricSofa);
  const { mechanicalSofa } = useSelector((state) => state.mechanicalSofa);

  const videoRef = useRef(null);
  const hideRef = useRef(null);

  const [showContent, setShowContent] = useState(false);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 577);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 576);
  };

  const togglePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }

      setIsPlaying(!isPlaying);
    }
  };

  const sectionRefs = useRef([]);

  // useEffect(() => {
  //   const observer = new IntersectionObserver(
  //     (entries) => {
  //       entries.forEach((entry) => {
  //         if (entry.isIntersecting) {
  //           console.log(`Đang nhìn thấy: ${entry.target.id}`);

  //           // if (
  //           //   !dataHomePage[entry.target.id.toLowerCase]
  //           //   // ||
  //           //   // dataHomePage[entry.target.id.toLowerCase].length === 0
  //           // ) {
  //           //   dispatch(
  //           //     getDataSection(SectionPath[entry.target.id.toUpperCase()])
  //           //   );
  //           // }
  //         }
  //       });
  //     },
  //     {
  //       root: null, // Quan sát trong viewport
  //       threshold: 0, // 50% của section phải vào viewport
  //     }
  //   );

  //   // Gắn observer vào từng section
  //   sectionRefs.current.forEach((section) => {
  //     if (section) observer.observe(section);
  //   });

  //   // Dọn dẹp khi component unmount
  //   return () => {
  //     sectionRefs.current.forEach((section) => {
  //       if (section) observer.unobserve(section);
  //     });
  //   };
  // }, []);

  useEffect(() => {
    window.scrollTo(0, 0);

    dispatch(getDataSection(SectionPath.HERO__IIEMS));
    dispatch(getDataSection(SectionPath.PRODUCT_GROUP));
    dispatch(getDataSection(SectionPath.VIDEO));
    dispatch(getDataSection(SectionPath.KEY_FEATURE));
    dispatch(getDataSection(SectionPath.FEATURE_ITEMS));
    dispatch(getDataSection(SectionPath.ELECTRIC_SOFA));
    dispatch(getDataSection(SectionPath.KEY_DETAILS));
    dispatch(getDataSection(SectionPath.MECHANICAL_SOFA));
    dispatch(getDataSection(SectionPath.INTRO_SHOWROOM));
    dispatch(getDataSection(SectionPath.VIRTUAL));
    dispatch(getDataSection(SectionPath.INSPIRE));

    dispatch(getDataSection(SectionPath.MORE_ELECTRIC_SOFA));
    dispatch(getDataSection(SectionPath.MORE_MECHANICAL_SOFA));

    dispatch(getElectricSofaAction(ProductPath.PROD_ELECTRIC_SOFA));
    dispatch(getMechanicalSofaAction(ProductPath.PROD_MECHANICAL_SOFA));

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="homepage">
      <section
        id="hero__items"
        ref={(el) => (sectionRefs.current[0] = el)}
        className="hero-section"
      >
        <Swiper
          className="hero-section__swiper"
          loop={true}
          speed={1250}
          spaceBetween={0}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
        >
          {dataHomePage?.hero__items?.items?.map((hItem) => {
            return (
              <SwiperSlide key={hItem._id}>
                <div className="overlay-img"></div>
                <img src={hItem.attachments[0]} alt="" />

                <div className="hero-section__swiper__content">
                  <h3
                    className="hero-section__swiper__content__title"
                    dangerouslySetInnerHTML={{
                      __html: hItem.title,
                    }}
                  />

                  <p
                    className="hero-section__swiper__content__text"
                    dangerouslySetInnerHTML={{
                      __html: hItem.content,
                    }}
                  />

                  <button
                    className="btn-main"
                    onClick={() => navigate("/product-category")}
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: hItem.content_button,
                      }}
                    />

                    <ICChevronRight width={18} height={18} />
                  </button>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </section>

      <section
        id="product_group"
        ref={(el) => (sectionRefs.current[1] = el)}
        className="product-group"
      >
        <div className="product-group__left">
          <img
            src={
              dataHomePage?.product_group?.attachments &&
              dataHomePage?.product_group?.attachments[0]
            }
            alt=""
          />
        </div>

        <div className="product-group__right">
          <h5
            className="product-group__right__heading"
            dangerouslySetInnerHTML={{
              __html: dataHomePage?.product_group?.sub_title,
            }}
          />

          <h3
            className="product-group__right__title"
            dangerouslySetInnerHTML={{
              __html: dataHomePage?.product_group?.title,
            }}
          />

          <p
            className="product-group__right__content"
            dangerouslySetInnerHTML={{
              __html: dataHomePage?.product_group?.content,
            }}
          />

          <button
            className="btn-main"
            onClick={() => navigate("/product-category")}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: dataHomePage?.product_group?.content_button,
              }}
            />
            <ICChevronRight width={18} height={18} />
          </button>
        </div>
      </section>

      <section
        id="video"
        ref={(el) => (sectionRefs.current[2] = el)}
        className="video-section"
      >
        {dataHomePage?.video?.attachments && !isMobile && (
          <video ref={videoRef} autoPlay muted>
            <source src={dataHomePage.video.attachments[0]} type="video/mp4" />
          </video>
        )}

        <button
          className="btn-play"
          onClick={() => {
            togglePlayPause();
          }}
        >
          {isPlaying ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M14 18.5V5.5H17.75V18.5H14ZM6.25 18.5V5.5H10V18.5H6.25Z"
                fill="#121212"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M8.5 18.0961V5.90405L18.0768 12.0001L8.5 18.0961Z"
                fill="#121212"
              />
            </svg>
          )}
        </button>
      </section>

      <section
        id="key_feature"
        ref={(el) => (sectionRefs.current[3] = el)}
        className="key-features"
      >
        <div className="key-features__show">
          <div className="key-features__show__left">
            <h5
              className="key-features__show__left__heading"
              dangerouslySetInnerHTML={{
                __html: dataHomePage?.key_feature?.sub_title,
              }}
            />

            <h3
              className="key-features__show__left__title"
              dangerouslySetInnerHTML={{
                __html: dataHomePage?.key_feature?.title,
              }}
            />

            <p
              className="key-features__show__left__content"
              dangerouslySetInnerHTML={{
                __html: dataHomePage?.key_feature?.content,
              }}
            />

            <button
              className="btn-transprent"
              onClick={() => {
                setShowContent(!showContent);
              }}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: dataHomePage?.key_feature?.content_button,
                }}
              />
              <ICChevronRight width={18} height={18} />
            </button>
          </div>

          <div className="key-features__show__right">
            <img src="./assets/images/home/key-features.png" alt="" />
          </div>
        </div>

        <div
          ref={hideRef}
          className={`key-features__hide ${
            showContent ? "key-features__hide--expanded" : ""
          }`}
        >
          <div className="line"></div>

          {isMobile ? (
            <div className="key-features__hide__main">
              <div className="slider-scroll-mobile">
                <Swiper
                  style={{ height: "430px" }}
                  freeMode={true}
                  slidesPerView={"auto"}
                  spaceBetween={10}
                  scrollbar={{ draggable: true }}
                  navigation={true}
                  speed={1250}
                  modules={[FreeMode, Navigation, Pagination, Scrollbar]}
                >
                  {dataHomePage?.feature__items?.items?.map((fItem, idx) => {
                    return (
                      <SwiperSlide key={fItem._id}>
                        <CardExpand data={fItem} />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            </div>
          ) : (
            <div className="key-features__hide__main">
              {dataHomePage?.feature__items?.items?.map((fItem) => {
                return (
                  <div
                    key={fItem._id}
                    className="key-features__hide__main__item"
                  >
                    <CardExpand data={fItem} />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </section>

      <section
        id="electric_sofa"
        ref={(el) => (sectionRefs.current[4] = el)}
        className="electric-sofa"
      >
        <div className="electric-sofa__image">
          <img src={dataHomePage?.electric_sofa?.attachments[0]} alt="" />
        </div>

        <div className="electric-sofa__absolute">
          <h5
            className="electric-sofa__absolute__heading"
            dangerouslySetInnerHTML={{
              __html: dataHomePage?.electric_sofa?.sub_title,
            }}
          />

          <h3
            className="electric-sofa__absolute__title"
            dangerouslySetInnerHTML={{
              __html: dataHomePage?.electric_sofa?.title,
            }}
          />

          <p
            className="electric-sofa__absolute__content"
            dangerouslySetInnerHTML={{
              __html: dataHomePage?.electric_sofa?.content,
            }}
          ></p>
        </div>
      </section>

      <section
        id="key_details"
        ref={(el) => (sectionRefs.current[5] = el)}
        className="key-details"
      >
        {!isMobile && (
          <div className="key-details-container">
            {dataHomePage?.key_details__items?.items?.map((kdItem) => {
              return (
                <div key={kdItem._id} className="key-details-container__card">
                  <div className="key-details-container__card__header">
                    <img src={kdItem.icon_title} alt="" />

                    {kdItem.title}
                  </div>

                  <video autoPlay loop muted>
                    <source src={kdItem.attachments[0]} type="video/mp4" />
                  </video>

                  <p
                    className="key-details-container__card__content"
                    dangerouslySetInnerHTML={{
                      __html: kdItem.content,
                    }}
                  />
                </div>
              );
            })}
          </div>
        )}
      </section>

      <section className="product-list-section">
        {isMobile ? (
          <div className="slider-scroll-mobile">
            <Swiper
              freeMode={true}
              slidesPerView={"auto"}
              spaceBetween={10}
              scrollbar={{ draggable: true }}
              navigation={true}
              speed={1250}
              modules={[FreeMode, Navigation, Pagination, Scrollbar]}
            >
              {electricSofa?.slice(0, 8)?.map((eSofa) => {
                return (
                  <SwiperSlide key={eSofa._id}>
                    <ProductCard product={eSofa} />
                  </SwiperSlide>
                );
              })}

              <SwiperSlide>
                <CardViewMore
                  data={dataHomePage?.more_electric_sofa}
                  categoryId={CategoryId.ELECTRIC_SOFA}
                />
              </SwiperSlide>
            </Swiper>
          </div>
        ) : (
          <div className="product-container">
            {electricSofa?.slice(0, 8)?.map((prod) => {
              return (
                <div key={prod._id} className="product-container__item">
                  <ProductCard product={prod} />
                </div>
              );
            })}

            <div className="product-container__item">
              <CardViewMore
                data={dataHomePage?.more_electric_sofa}
                categoryId={CategoryId.ELECTRIC_SOFA}
              />
            </div>
          </div>
        )}
      </section>

      <section
        id="mechanical_sofa"
        ref={(el) => (sectionRefs.current[6] = el)}
        className="mechanical-sofa"
      >
        <div className="mechanical-sofa__top">
          <h5
            className="mechanical-sofa__top__heading"
            dangerouslySetInnerHTML={{
              __html: dataHomePage?.mechanical_sofa?.sub_title,
            }}
          />

          <h3
            className="mechanical-sofa__top__title"
            dangerouslySetInnerHTML={{
              __html: dataHomePage?.mechanical_sofa?.title,
            }}
          />

          <p
            className="mechanical-sofa__top__content"
            dangerouslySetInnerHTML={{
              __html: dataHomePage?.mechanical_sofa?.content,
            }}
          ></p>
        </div>

        {isMobile ? (
          <div className="key-features__hide__main">
            <div className="slider-scroll-mobile">
              <Swiper
                freeMode={true}
                slidesPerView={"auto"}
                spaceBetween={10}
                scrollbar={{ draggable: true }}
                navigation={true}
                speed={1250}
                modules={[FreeMode, Navigation, Pagination, Scrollbar]}
              >
                {mechanicalSofa?.slice(0, 8)?.map((mSofa) => {
                  return (
                    <SwiperSlide key={mSofa._id}>
                      <ProductCard product={mSofa} />
                    </SwiperSlide>
                  );
                })}

                <SwiperSlide>
                  <CardViewMore
                    data={dataHomePage?.more_electric_mechanical}
                    categoryId={CategoryId.MECHANICAL_SOFA}
                  />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        ) : (
          <div className="product-container">
            {mechanicalSofa?.slice(0, 8)?.map((prod) => {
              return (
                <div key={prod._id} className="product-container__item">
                  <ProductCard product={prod} />
                </div>
              );
            })}

            <div className="product-container__item">
              <CardViewMore
                data={dataHomePage?.more_electric_mechanical}
                categoryId={CategoryId.MECHANICAL_SOFA}
              />
            </div>
          </div>
        )}
      </section>

      <section className="intro-showroom">
        <img
          className="intro-showroom__image"
          src="./assets/images/home/intro-showroom.png"
          alt=""
        />

        <div className="intro-showroom__content">
          <h3
            className="intro-showroom__content__title"
            dangerouslySetInnerHTML={{
              __html: dataHomePage?.intro_showroom?.title,
            }}
          />

          <p
            className="intro-showroom__content__text"
            dangerouslySetInnerHTML={{
              __html: dataHomePage?.intro_showroom?.content,
            }}
          />

          <button
            className="btn-main"
            onClick={() => window.open("https://zalo.me/0941446571", "_blank")}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: dataHomePage?.intro_showroom?.content_button,
              }}
            />
            <ICChevronRight width={18} height={18} />
          </button>
        </div>
      </section>

      <section className="virtual">
        <div className="virtual-container">
          <div className="virtual-container__left">
            <h5
              className="virtual-container__left__heading"
              dangerouslySetInnerHTML={{
                __html: dataHomePage?.virtual?.sub_title,
              }}
            />

            <h3
              className="virtual-container__left__title"
              dangerouslySetInnerHTML={{
                __html: dataHomePage?.virtual?.title,
              }}
            />

            <p
              className="virtual-container__left__content"
              dangerouslySetInnerHTML={{
                __html: dataHomePage?.virtual?.content,
              }}
            />

            <button
              className="btn-main"
              onClick={() =>
                window.open("https://designv2.lanha.vn/", "_blank")
              }
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: dataHomePage?.virtual?.content_button,
                }}
              />
              <ICChevronRight width={18} height={18} />
            </button>
          </div>

          <div
            className="virtual-container__right"
            onClick={() => window.open("https://designv2.lanha.vn/", "_blank")}
          >
            {dataHomePage?.virtual?.attachments && (
              <video playsInline autoPlay loop muted>
                <source
                  src={dataHomePage?.virtual?.attachments[0]}
                  type="video/mp4"
                />
              </video>
            )}
          </div>
        </div>
      </section>

      <section className="inspire">
        <div className="inspire__top">
          <h5
            className="inspire__top__heading"
            dangerouslySetInnerHTML={{
              __html: dataHomePage?.inspire?.sub_title,
            }}
          />

          <h3
            className="inspire__top__title"
            dangerouslySetInnerHTML={{
              __html: dataHomePage?.inspire?.title,
            }}
          />

          <p
            className="inspire__top__content"
            dangerouslySetInnerHTML={{
              __html: dataHomePage?.inspire?.content,
            }}
          />
        </div>

        <div>
          <Swiper
            className="inspire__swiper"
            freeMode={true}
            slidesPerView={"auto"}
            spaceBetween={10}
            scrollbar={{ draggable: true }}
            navigation={true}
            speed={1250}
            modules={[FreeMode, Navigation, Pagination, Scrollbar]}
          >
            {dataHomePage?.inspire?.attachments?.map((sItem, idx) => {
              return (
                <SwiperSlide key={idx}>
                  <img src={sItem} alt="" />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </section>
    </div>
  );
}
