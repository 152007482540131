import "./Chip.scss";

const ChipSuggest32 = ({ title, onClick, isSelected }) => {
  return (
    <div
      className={`chip--suggest ${isSelected ? "selected" : ""}`}
      onClick={onClick}
    >
      <div className="chip--suggest-title">
        <span>{title}</span>
      </div>
    </div>
  );
};

export default ChipSuggest32;
