import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  productDetail: {},
};

export const ProductDetailReducer = createSlice({
  name: "productDetail",
  initialState,
  reducers: {
    getProductDetail: (state, action) => {
      state.productDetail = action.payload;
    },
    removeProductDetail: (state, action) => {
      state.productDetail = {};
    },
  },
});

export const { getProductDetail, removeProductDetail } =
  ProductDetailReducer.actions;

export default ProductDetailReducer.reducer;
