const ICChevronRight = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 24 24`}
      fill="currentColor"
    >
      <path d="M13.4467 11.9999L8.84668 7.39994L9.90043 6.34619L15.5542 11.9999L9.90043 17.6537L8.84668 16.5999L13.4467 11.9999Z" />
    </svg>
  );
};
export default ICChevronRight;
