const ICChevronLeft = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="currentColor"
    >
      <path d="M11.6665 14.7114L6.95508 9.99997L11.6665 5.28851L12.5447 6.16664L8.71133 9.99997L12.5447 13.8333L11.6665 14.7114Z" />
    </svg>
  );
};
export default ICChevronLeft;
