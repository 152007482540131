import ICSearch from "../../assets/icons/ICSearch";
import "./Input.scss";
import TextFiled from "./TextFiled";

const SearchTextField = ({ value=undefined, setValue=()=>{}, onKeyDown=()=>{} }) => {

  return (
    <TextFiled
      posIcon={<ICSearch width={24} height={24} />}
      placeholder={"Sofa điện, sofa cơ, sofa cơ bản,..."}
      value={value??""}
      setValue={setValue}
      onKeyDown={onKeyDown}
    />
  );
};

export default SearchTextField;
