import "./ProductCard.scss";
import Placeholder from "../../assets/images/example.jpeg";
import Sofa1 from "../../assets/images/all.png";
import Sofa2 from "../../assets/images/sofadien.png";
import Sofa3 from "../../assets/images/sofacoban.png";
import Sofa4 from "../../assets/images/sofaco.png";
import ICBookmark from "../../assets/icons/ICBookmark";
import TagPrimium from "../Tag/TagPrimium";
import TagPrice from "../Tag/TagPrice";
import CustomSwiper from "../Swiper/CustomSwiper";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Tag from "../Tag/Tag";
import { formatToVND } from "../../utils/currency";
import React from "react";

const Slide = ({ image }) => (
  <div>
    <img src={image} alt="" />
  </div>
);

const ProductCard = React.memo(({ product, allowTouchMove }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const nameSearch = searchParams.get("name");

  return (
    <div
      className="product--card"
      id={product?._id}
      onClick={() => {
        navigate(`/product-detail?_id=${product._id}&name=${nameSearch}`);
        //  window.location.reload();
      }}
    >
      {/* {product?.price_after ? (
        <TagPrice title={"Giá mới"} absoluteTopLeft={true} />
      ) : (
        <TagPrimium text={"Hàng mới về"} absoluteTopLeft={true} />
      )} */}

      <div className="product--card-image">
        <CustomSwiper
          allowTouchMove={allowTouchMove}
          swipers={
            product?.images?.map((image, i) => (
              <Slide image={image} key={i} />
            )) ?? [
              <img src={Placeholder} alt="" />,
              <img src={Sofa1} alt="" />,
              <img src={Sofa2} alt="" />,
              <img src={Sofa3} alt="" />,
              <img src={Sofa4} alt="" />,
            ]
          }
        />
      </div>
      <div className="product--card--info">
        <div className="product--card--info-title">
          <div className="hover-underline-animation product--card--info-name">
            <span>{product?.name || "Sofa"}</span>
          </div>
          <div width={18} height={18}>
            <ICBookmark width={18} height={18} />
          </div>
        </div>
        <div className="product--card--info--detail">
          <div className="product--card--info--detail-category">
            <span>{product?.description || "Mô tả"}</span>
            <div className="color">
              {product?.icons_texture?.map((color, i) => {
                if (i < 3)
                  return (
                    <div
                      key={i}
                      className="rect"
                      style={{ backgroundColor: "#89857F" }}
                    >
                      <img
                        src={color}
                        alt=""
                        style={{ verticalAlign: "top" }}
                      />
                    </div>
                  );
              })}

              {product?.icons_texture?.length > 3 ? (
                <span>+{product?.icons_texture?.length - 3}</span>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div
            className={`product--card--info--detail-price ${
              product?.price_after ? "product--card--info--detail-sale" : ""
            }`}
          >
            <span className="original">
              {formatToVND(product?.price_original)}
            </span>
            {product?.price_after ? (
              <div className="sale">
                <span className="content">
                  {formatToVND(product?.price_after)}
                </span>
                {/* <TagPrice text={"sale"} /> */}
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="product--card--info--detail-tag">
            {product?.tags?.map((tag, i) => (
              <Tag title={tag} key={i} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
});

export default ProductCard;
