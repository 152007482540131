import { useEffect } from "react";
import ICDropdown from "../../assets/icons/ICDropdown";

import "./ButtonDropdown.scss";

const ButtonDropdown = ({
  title,
  id,
  badge,
  posIcon,
  preIcon,
  container,
  isHidden,
style,
  isTranslateX,
  isSmall,
}) => {
  useEffect(() => {
    const dropdown = document.getElementById(id + "Dropdown-Container");
    const button = document.getElementById(id);
    const buttonClose = document.getElementById(id + "Close");

    if (dropdown && button) {
      if (buttonClose) {
        buttonClose.addEventListener("click", (e) => {
          if (isHidden) {
            dropdown.classList.remove("show");
            dropdown.classList.add("hidden");
          }
          if (isTranslateX) {
            dropdown.classList.add("shrink");
          }
        });
      }
      window.addEventListener("click", (e) => {
        if (!button.contains(e.target)) {
          if (isHidden) {
            dropdown.classList.remove("show");
            dropdown.classList.add("hidden");
          }
          if (isTranslateX) {
            dropdown.classList.add("shrink");
          }
        }
      });
      return () => {
        if (buttonClose) {
          buttonClose.removeEventListener("click", (e) => {
            if (isHidden) {
              dropdown.classList.remove("show");
              dropdown.classList.add("hidden");
            }
            if (isTranslateX) {
              dropdown.classList.add("shrink");
            }
          });
        }
        window.removeEventListener("click", (e) => {
          if (!button.contains(e.target)) {
            if (isHidden) {
              dropdown.classList.remove("show");
              dropdown.classList.add("hidden");
            }
            if (isTranslateX) {
              dropdown.classList.add("shrink");
            }
          }
        });
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return (
    <div
      className={`btn--dropdown ${isSmall&&"small"}`}
      style={{...style}}
      id={id}
      onClick={(e) => {
        e.preventDefault();
        // e.stopPropagation();
        const dropdown = document.getElementById(id + "Dropdown-Container");
        if (dropdown) {
          if (isHidden) {
            const hidden = dropdown.classList.contains("hidden");
            if (hidden) {
              dropdown.classList.remove("hidden");
              dropdown.classList.add("show");
            } else {
              dropdown.classList.remove("show");
              dropdown.classList.add("hidden");
            }
          }
          if (isTranslateX) {
            dropdown.classList.remove("shrink");
          }
        }
      }}
    >
      <div className="btn--dropdown--content">
        {posIcon}
        <div className="btn--dropdown--content-title">{title}</div>
        <div className="btn--dropdown--content-icon">
          <ICDropdown width={16} height={16} />
        </div>
      </div>
      {badge !== undefined && (
        <div className="btn--dropdown--badge">
          <div className="btn--dropdown--badge-content">{badge}</div>
        </div>
      )}
      <div
        className={`dropdown--container ${
          isHidden ? "hidden" : isTranslateX ? "full--screen shrink" : ""
        } `}
        id={id + "Dropdown-Container"}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {container}
      </div>
    </div>
  );
};

export default ButtonDropdown;
