const ICAMEX = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="20"
      viewBox="0 0 29 20"
      fill="none"
    >
      <rect x="0.666992" width="28.3333" height="20" rx="1" fill="#1F72CD" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.74663 7.0835L3.0957 13.1224H6.26923L6.66266 12.1596H7.56194L7.95537 13.1224H11.4485V12.3876L11.7598 13.1224H13.5667L13.878 12.372V13.1224H21.1428L22.0262 12.1846L22.8533 13.1224L26.5847 13.1302L23.9254 10.1198L26.5847 7.0835H22.9112L22.0513 8.00402L21.2502 7.0835H13.347L12.6684 8.6422L11.9738 7.0835H8.8069V7.79338L8.4546 7.0835H5.74663ZM6.36069 7.94104H7.90762L9.66598 12.0361V7.94104H11.3606L12.7187 10.8772L13.9704 7.94104H15.6565V12.2744H14.6305L14.6222 8.8788L13.1264 12.2744H12.2086L10.7045 8.8788V12.2744H8.59381L8.19367 11.3029H6.03186L5.63254 12.2735H4.50168L6.36069 7.94104ZM20.767 7.94104H16.5952V12.2718H20.7024L22.0262 10.8365L23.3022 12.2718H24.636L22.6973 10.119L24.636 7.94104H23.36L22.0429 9.35985L20.767 7.94104ZM7.11322 8.67422L6.40098 10.4048H7.82462L7.11322 8.67422ZM17.6254 9.6293V8.83825V8.8375H20.2285L21.3643 10.1026L20.1781 11.3746H17.6254V10.511H19.9013V9.6293H17.6254Z"
        fill="white"
      />
    </svg>
  );
};

export default ICAMEX;
