import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mechanicalSofa: [],
};

export const MechanicalSofaReducer = createSlice({
  name: "mechanicalSofa",
  initialState,
  reducers: {
    getMechanicalSofa: (state, action) => {
      state.mechanicalSofa = action.payload;
    },
  },
});

export const { getMechanicalSofa } = MechanicalSofaReducer.actions;

export default MechanicalSofaReducer.reducer;
