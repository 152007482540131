import axios from "axios";

export default async function callApi(
  endpoint,
  method = "GET",
  data,
  headers,
  params
) {
  try {
    return await axios({
      method: method,
      url: `${process.env.REACT_APP_API_URL}${endpoint}`,
      data: data,
      headers: headers,
      params: params,
    });
  } catch (error) {
    if (error.response) {
      return error.response.data;
    } else if (error.request) {
    } else {
    }
  }
}
