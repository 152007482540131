const ICLocation = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="currentColor"
    >
      <path d="M8.00094 7.91C8.33261 7.91 8.61616 7.79189 8.85161 7.55567C9.08716 7.31945 9.20494 7.0355 9.20494 6.70384C9.20494 6.37217 9.08683 6.08856 8.85061 5.853C8.61438 5.61756 8.33038 5.49984 7.99861 5.49984C7.66694 5.49984 7.38338 5.61795 7.14794 5.85417C6.91238 6.09039 6.79461 6.37439 6.79461 6.70617C6.79461 7.03784 6.91272 7.32139 7.14894 7.55684C7.38516 7.79228 7.66916 7.91 8.00094 7.91ZM7.99977 13.0088C9.304 11.8413 10.3021 10.7214 10.9939 9.64917C11.6858 8.57695 12.0318 7.63784 12.0318 6.83184C12.0318 5.6165 11.6457 4.61739 10.8734 3.8345C10.1012 3.05162 9.14333 2.66017 7.99977 2.66017C6.85622 2.66017 5.89833 3.05162 5.12611 3.8345C4.35388 4.61739 3.96777 5.6165 3.96777 6.83184C3.96777 7.63784 4.31372 8.57695 5.00561 9.64917C5.6975 10.7214 6.69555 11.8413 7.99977 13.0088ZM7.99977 14.3395C6.322 12.8857 5.06388 11.5328 4.22544 10.2807C3.387 9.02845 2.96777 7.87884 2.96777 6.83184C2.96777 5.29339 3.46538 4.04789 4.46061 3.09534C5.45594 2.14278 6.63566 1.6665 7.99977 1.6665C9.36388 1.6665 10.5436 2.14278 11.5389 3.09534C12.5342 4.04789 13.0318 5.29339 13.0318 6.83184C13.0318 7.87884 12.6126 9.02845 11.7741 10.2807C10.9357 11.5328 9.67755 12.8857 7.99977 14.3395Z" />
    </svg>
  );
};

export default ICLocation;
