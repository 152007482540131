import "./Button.scss";

const ButtonIconLarge = ({ id, icon, disabled, onClick, type }) => {
  return (
    <button
      id={id}
      className="btn--icon large"
      disabled={disabled}
      onClick={onClick}
      type={type}
    >
      {icon}
    </button>
  );
};
export default ButtonIconLarge;
