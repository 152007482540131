import ProductCategory from "../pages/ProductCategory/ProductCategory";
import HomePage from "../pages/Home/HomePage";
import ProductDetail from "../pages/ProductDetail/ProductDetail";
import SearchPage from "../pages/SearchPage/SearchPage";
import CheckoutPage from "../pages/Checkout/CheckoutPage";

const routers = [
  {
    path: "/",
    element: <HomePage />,
    private: false,
    isChild: true,
  },
  {
    path: "/product-detail",
    element: <ProductDetail />,
    private: false,
    isChild: true,
  },

  {
    path: "/product-category",
    element: <ProductCategory />,
    private: false,
    isChild: true,
  },
  {
    path: "/result-search",
    element: <SearchPage />,
    private: false,
    isChild: true,
  },
  {
    path: "/checkout",
    element: <CheckoutPage />,
    private: false,
    isChild: true,
  },

  //   {
  //     path: "/*",
  //     element: <PageNotFound />,
  //     private: false,
  //   },
];

export default routers;
