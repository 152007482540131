const Logo = ({ width, height }) => {
  return (
    <svg
      // width={width}
      // height={height}
      style={{ width: width ?? "100%", height: height ?? "auto" }}
      viewBox="0 0 94 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group">
        <g id="Group_2">
          <g id="Group_3">
            <path
              id="Vector"
              d="M25.2284 18.784V14.3604L12.2381 2.30537L2.29584 11.4058V29.6781H26.9231V31.3728H0.595703V10.6575L12.2436 0L26.9231 13.6176V22.641L13.1679 10.0248L12.2271 9.0839L13.3275 7.87345L25.2284 18.784Z"
            />
          </g>
        </g>
        <g id="Group_4">
          <g id="Group_5">
            <g id="Group_6">
              <path
                id="Vector_2"
                d="M65.2451 39.8733V33.7275H67.2039C67.2534 33.7275 67.3579 33.7275 67.5065 33.733C67.6605 33.7385 67.8036 33.744 67.9466 33.7606C68.4308 33.8211 68.8435 33.9971 69.1846 34.2777C69.5257 34.5584 69.7788 34.9215 69.9494 35.3616C70.1254 35.8018 70.208 36.2805 70.208 36.7977C70.208 37.3204 70.1199 37.7991 69.9494 38.2337C69.7733 38.6739 69.5202 39.0315 69.1846 39.3176C68.849 39.6037 68.4363 39.7743 67.9466 39.8348C67.8091 39.8513 67.6605 39.8623 67.5065 39.8623C67.3524 39.8678 67.2479 39.8678 67.1983 39.8678H65.2451V39.8733ZM66.2905 38.905H67.2039C67.2919 38.905 67.3964 38.905 67.5285 38.894C67.6605 38.8885 67.7761 38.8775 67.8806 38.8555C68.1722 38.8004 68.4088 38.6739 68.5904 38.4648C68.7719 38.2612 68.9095 38.0136 68.992 37.7165C69.0801 37.4249 69.1241 37.1168 69.1241 36.7922C69.1241 36.4566 69.0801 36.1484 68.992 35.8513C68.904 35.5597 68.7664 35.3121 68.5849 35.1141C68.4033 34.916 68.1667 34.7894 67.8806 34.7344C67.7761 34.7124 67.6605 34.6959 67.5285 34.6959C67.3964 34.6904 67.2864 34.6904 67.2039 34.6904H66.2905V38.905Z"
              />
            </g>
            <g id="Group_7">
              <path
                id="Vector_3"
                d="M71.2207 39.8733V33.7275H75.1877V34.6904H72.2441V36.2035H74.6705V37.1663H72.2441V38.905H75.1877V39.8678H71.2207V39.8733Z"
              />
            </g>
            <g id="Group_8">
              <path
                id="Vector_4"
                d="M78.6041 40.0001C77.9879 40.0001 77.4652 39.868 77.025 39.5984C76.5849 39.3288 76.2492 38.9547 76.0181 38.476C75.7816 37.9973 75.666 37.4361 75.666 36.8034C75.666 36.1706 75.7816 35.6094 76.0181 35.1308C76.2547 34.6521 76.5904 34.2724 77.025 34.0083C77.4652 33.7387 77.9879 33.6067 78.6041 33.6067C79.3139 33.6067 79.9026 33.7828 80.3758 34.1404C80.849 34.4925 81.1791 34.9712 81.3717 35.5709L80.3373 35.857C80.2162 35.4554 80.0126 35.1418 79.7265 34.9162C79.4404 34.6906 79.0663 34.5751 78.6096 34.5751C78.197 34.5751 77.8558 34.6686 77.5807 34.8502C77.3056 35.0372 77.1021 35.2958 76.9645 35.6314C76.8269 35.9671 76.7554 36.3577 76.7554 36.8089C76.7554 37.2601 76.8269 37.6507 76.9645 37.9863C77.1021 38.322 77.3111 38.5806 77.5862 38.7676C77.8613 38.9547 78.208 39.0427 78.6206 39.0427C79.0773 39.0427 79.4514 38.9272 79.7375 38.7016C80.0236 38.476 80.2272 38.1624 80.3483 37.7607L81.3827 38.0469C81.1901 38.6466 80.86 39.1253 80.3868 39.4774C79.9026 39.824 79.3139 40.0001 78.6041 40.0001Z"
              />
            </g>
            <g id="Group_9">
              <path
                id="Vector_5"
                d="M84.9098 40.0001C84.2936 40.0001 83.7709 39.868 83.3307 39.5984C82.8905 39.3288 82.5549 38.9547 82.3238 38.476C82.0872 37.9973 81.9717 37.4361 81.9717 36.8034C81.9717 36.1706 82.0872 35.6094 82.3238 35.1308C82.5604 34.6521 82.896 34.2724 83.3307 34.0083C83.7709 33.7387 84.2936 33.6067 84.9098 33.6067C85.526 33.6067 86.0487 33.7387 86.4889 34.0083C86.929 34.2779 87.2647 34.6521 87.5013 35.1308C87.7378 35.6094 87.8534 36.1706 87.8534 36.8034C87.8534 37.4361 87.7378 37.9973 87.5013 38.476C87.2647 38.9547 86.929 39.3343 86.4889 39.5984C86.0487 39.868 85.5205 40.0001 84.9098 40.0001ZM84.9098 39.0317C85.3224 39.0372 85.6636 38.9437 85.9387 38.7566C86.2138 38.5695 86.4173 38.3109 86.5549 37.9753C86.6925 37.6397 86.764 37.2491 86.764 36.7979C86.764 36.3467 86.6925 35.9561 86.5549 35.6259C86.4173 35.2958 86.2083 35.0372 85.9387 34.8502C85.6636 34.6631 85.3224 34.5695 84.9098 34.564C84.4971 34.5585 84.156 34.6521 83.8809 34.8391C83.6058 35.0262 83.4022 35.2848 83.2647 35.6204C83.1271 35.9561 83.0556 36.3467 83.0556 36.7979C83.0556 37.2491 83.1216 37.6397 83.2592 37.9698C83.3967 38.2999 83.6003 38.5585 83.8754 38.7456C84.1505 38.9327 84.4971 39.0262 84.9098 39.0317Z"
              />
            </g>
            <g id="Group_10">
              <path
                id="Vector_6"
                d="M92.2659 37.5405C92.541 37.4249 92.7611 37.2489 92.9206 37.0178C93.1737 36.6546 93.3003 36.2255 93.3003 35.7248C93.3003 35.3837 93.2398 35.0755 93.1297 34.8004C93.0142 34.5253 92.8436 34.2943 92.6125 34.1127C92.3814 33.9311 92.0898 33.8156 91.7322 33.7606C91.6552 33.7495 91.5671 33.7385 91.4736 33.733C91.3801 33.7275 91.303 33.7275 91.2425 33.7275H88.7061V39.8733H89.7349V37.7275H91.1985L92.2384 39.8733H93.4048L92.2659 37.5405ZM89.7349 34.6904H91.204C91.2645 34.6904 91.325 34.6959 91.3966 34.6959C91.4681 34.7014 91.5341 34.7124 91.5946 34.7289C91.7652 34.7729 91.8973 34.8445 91.9963 34.9545C92.0953 35.0645 92.1613 35.1856 92.2054 35.3231C92.2494 35.4607 92.2659 35.5982 92.2659 35.7248C92.2659 35.8568 92.2439 35.9889 92.2054 36.1264C92.1613 36.264 92.0953 36.385 91.9963 36.4951C91.8973 36.6051 91.7597 36.6821 91.5946 36.7207C91.5341 36.7372 91.4681 36.7482 91.3966 36.7537C91.325 36.7592 91.2645 36.7647 91.204 36.7647H89.7349V34.6904Z"
              />
            </g>
          </g>
          <g id="Group_11">
            <g id="Group_12">
              <g id="Group_13">
                <g id="Group_14">
                  <path
                    id="Vector_7"
                    d="M48.9752 20.9745C48.9202 20.4518 48.7661 19.9401 48.5131 19.4395C48.0454 18.4986 47.3356 17.8219 46.3727 17.4147C45.4099 17.002 44.304 16.793 43.0385 16.793C41.3659 16.793 40.0344 17.1561 39.044 17.8879C38.0591 18.6141 37.3879 19.577 37.0357 20.782L39.9298 21.6898C40.1664 20.9635 40.5846 20.4463 41.1788 20.1492C41.7785 19.8521 42.3948 19.7036 43.033 19.7036C44.0949 19.7036 44.8487 19.9346 45.2998 20.4023C45.674 20.7875 45.8831 21.3542 45.9436 22.1025C45.3714 22.185 44.8102 22.2675 44.2655 22.3445C43.3631 22.4711 42.5323 22.6086 41.7675 22.7572C41.0027 22.9058 40.3315 23.0763 39.7593 23.2579C39.0055 23.511 38.3893 23.8356 37.9161 24.2373C37.4429 24.6334 37.0908 25.1121 36.8652 25.6568C36.6341 26.2015 36.5186 26.8177 36.5186 27.5055C36.5186 28.2868 36.7001 29.002 37.0633 29.6513C37.4264 30.3005 37.9656 30.8232 38.6754 31.2139C39.3851 31.6045 40.2545 31.7971 41.2833 31.7971C42.5653 31.7971 43.6327 31.555 44.4855 31.0763C45.1458 30.7132 45.74 30.1795 46.2682 29.4862V31.3954H49.0467V22.6692C49.0522 22.0639 49.0247 21.4972 48.9752 20.9745ZM45.8776 25.5247C45.8556 26.0474 45.762 26.5096 45.5915 26.9113C45.4869 27.2359 45.2778 27.5825 44.9807 27.9511C44.6781 28.3198 44.2765 28.6279 43.7648 28.8865C43.2586 29.1396 42.6424 29.2661 41.9216 29.2661C41.4209 29.2661 41.0027 29.1891 40.6671 29.0296C40.3315 28.87 40.0729 28.6554 39.8968 28.3913C39.7208 28.1272 39.6327 27.8136 39.6327 27.467C39.6327 27.1589 39.6987 26.8948 39.8363 26.6637C39.9738 26.4381 40.1664 26.2345 40.4195 26.0584C40.6781 25.8824 40.9862 25.7283 41.3549 25.5963C41.7345 25.4752 42.1637 25.3652 42.6589 25.2606C43.1485 25.1616 43.7373 25.0516 44.425 24.936C44.8652 24.8645 45.3549 24.7875 45.9051 24.6994C45.8996 24.9415 45.8886 25.2166 45.8776 25.5247Z"
                  />
                </g>
              </g>
              <g id="Group_15">
                <g id="Group_16">
                  <path
                    id="Vector_8"
                    d="M31.6162 31.4062V12.0664H34.7854V31.4117H31.6162V31.4062Z"
                  />
                </g>
              </g>
              <g id="Group_17">
                <g id="Group_18">
                  <path
                    id="Vector_9"
                    d="M64.7334 23.4721V31.4061H61.5202V24.5615C61.5202 24.1159 61.4927 23.6207 61.4322 23.076C61.3717 22.5313 61.2286 22.0086 61.003 21.5024C60.7774 20.9962 60.4473 20.5835 60.0016 20.2589C59.556 19.9343 58.9617 19.7747 58.2025 19.7747C57.8008 19.7747 57.4047 19.8408 57.0085 19.9728C56.6124 20.1049 56.2547 20.3304 55.9356 20.6496C55.6165 20.9687 55.3579 21.4088 55.1653 21.9645C54.9728 22.5203 54.8737 23.2355 54.8737 24.1049V31.4061H51.666V17.1943H54.4941V18.7789C54.8517 18.3497 55.2809 17.9811 55.7706 17.6619C56.6839 17.0787 57.8063 16.7871 59.1378 16.7871C60.1887 16.7871 61.058 16.9632 61.7403 17.3098C62.428 17.6619 62.9672 18.1076 63.3744 18.6523C63.7761 19.197 64.0732 19.7747 64.2657 20.391C64.4583 21.0017 64.5849 21.5849 64.6399 22.1351C64.7059 22.6798 64.7334 23.1255 64.7334 23.4721Z"
                  />
                </g>
              </g>
              <g id="Group_19">
                <g id="Group_20">
                  <path
                    id="Vector_10"
                    d="M79.3242 23.4723V31.4063H76.1165V24.5617C76.1165 24.116 76.089 23.6208 76.023 23.0761C75.9625 22.5314 75.8194 22.0087 75.5993 21.5026C75.3738 20.9964 75.0381 20.5837 74.598 20.2591C74.1523 19.9345 73.5581 19.7749 72.7988 19.7749C72.3916 19.7749 71.9955 19.8409 71.5993 19.973C71.2087 20.105 70.8456 20.3306 70.5264 20.6497C70.2073 20.9689 69.9487 21.409 69.7561 21.9647C69.5636 22.5204 69.4645 23.2357 69.4645 24.105V31.4063H66.2568V12.4517H69.0849V18.7735C69.4425 18.3444 69.8717 17.9757 70.3614 17.6566C71.2747 17.0734 72.3971 16.7818 73.7286 16.7818C74.785 16.7818 75.6489 16.9578 76.3366 17.3045C77.0244 17.6566 77.5636 18.1023 77.9652 18.647C78.3724 19.1917 78.6695 19.7694 78.8621 20.3856C79.0546 20.9964 79.1812 21.5796 79.2362 22.1298C79.2967 22.68 79.3242 23.1257 79.3242 23.4723Z"
                  />
                </g>
              </g>
              <g id="Group_21">
                <g id="Group_22">
                  <path
                    id="Vector_11"
                    d="M92.9867 20.9797C92.9372 20.457 92.7831 19.9453 92.5245 19.4446C92.0568 18.5037 91.3471 17.8325 90.3842 17.4198C89.4213 17.0072 88.3154 16.7981 87.05 16.7981C85.3718 16.7981 84.0403 17.1612 83.0555 17.893C82.0706 18.6193 81.3993 19.5876 81.0527 20.7871L83.9468 21.6949C84.1834 20.9687 84.6015 20.4515 85.1958 20.1544C85.7955 19.8572 86.4117 19.7087 87.0555 19.7087C88.1174 19.7087 88.8711 19.9398 89.3168 20.4075C89.6909 20.7926 89.9 21.3593 89.9606 22.1076C89.3883 22.1901 88.8271 22.2726 88.2824 22.3497C87.3801 22.4762 86.5493 22.6138 85.7845 22.7623C85.0197 22.9109 84.3484 23.0815 83.7817 23.263C83.0279 23.5161 82.4117 23.8407 81.9385 24.2424C81.4654 24.644 81.1132 25.1172 80.8821 25.6619C80.6511 26.2066 80.541 26.8284 80.541 27.5106C80.541 28.2919 80.7226 29.0072 81.0857 29.6564C81.4489 30.3057 81.9881 30.8284 82.6978 31.219C83.4131 31.6096 84.2769 31.8022 85.3058 31.8022C86.5878 31.8022 87.6552 31.5656 88.508 31.0815C89.1683 30.7128 89.7625 30.1846 90.2907 29.4914V31.4061H93.0637V22.6798C93.0692 22.0691 93.0417 21.5024 92.9867 20.9797ZM89.889 25.5299C89.867 26.0526 89.7735 26.5147 89.6029 26.9164C89.4984 27.241 89.2948 27.5876 88.9922 27.9563C88.6896 28.3249 88.2879 28.633 87.7762 28.8916C87.27 29.1447 86.6538 29.2713 85.933 29.2713C85.4323 29.2713 85.0142 29.1942 84.6786 29.0347C84.3429 28.8751 84.0843 28.6605 83.9083 28.3964C83.7322 28.1323 83.6442 27.8187 83.6442 27.4721C83.6442 27.164 83.7157 26.8999 83.8478 26.6688C83.9853 26.4432 84.1779 26.2396 84.431 26.0636C84.6841 25.8875 84.9977 25.7334 85.3663 25.6014C85.746 25.4804 86.1806 25.3703 86.6703 25.2658C87.16 25.1667 87.7487 25.0567 88.4365 24.9412C88.8766 24.8696 89.3718 24.7926 89.9165 24.7046C89.911 24.9467 89.9055 25.2218 89.889 25.5299Z"
                  />
                </g>
              </g>
            </g>
            <path
              id="Vector_12"
              d="M45.1844 15.1533H42.4499L39.0771 12.0281H41.8117L45.1844 15.1533Z"
            />
            <path
              id="Vector_13"
              d="M89.2004 15.1533H86.4659L83.0986 12.0281H85.8332L89.2004 15.1533Z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Logo;
