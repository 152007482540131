import callApi from "../../utils/callApi";
import { getSimilarSofa } from "../reducers/similarSofa.reducers";

export const getSimilarSofaAction = (path) => {
  return async (dispatch) => {
    try {
      const res = await callApi(path, "GET", null);

      await dispatch(getSimilarSofa(res.data.data));
    } catch (err) {
      console.log(err);
    }
  };
};
