import "./SearchPage.scss";
import "../ProductCategory/MobileFilter.scss";
import SearchTextField from "../../components/Input/SearchTextField";
import TabLarge from "../../components/Tab/TabLarge";
import ButtonDropdown from "../../components/ButtonDropdown/ButtonDropdown";
import { useEffect, useState } from "react";
import ProductCard from "../../components/ProductCard/ProductCard";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import {
  getDataProduct,
  getDataSimilarColorFilter,
  getDataMaterialFilter,
} from "../../store/actions/productCategory.action";
import MobileFilter from "../ProductCategory/MobileFilter";
import ButtonDropdownMaterial from "../ProductCategory/ButtonDropdownMaterial";
import ButtonDropdownPrice from "../ProductCategory/ButtonDropdownPrice";
import ButtonDropdownColor from "../ProductCategory/ButtonDropdownColor";

const SearchPage = () => {
  let minStep = 100000;
  let minRange = 0;
  let maxRange = 100000000;

  function useDebounce(value, delay = 500) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);

    return debouncedValue;
  }

  const [colorArray, setColorArray] = useState([]);
  const [materialArray, setMaterialArray] = useState([]);

  const dispatch = useDispatch();
  const { dataProductCategory } = useSelector(
    (state) => state.dataProductCategory
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const nameSearch = searchParams.get("name");

  const categoryFilterString = searchParams.get("_id");
  const colorFilterString = searchParams.get("color");
  const materialFilterString = searchParams.get("material");
  const fromFilterString = searchParams.get("from");
  const toFilterString = searchParams.get("to");
  // const offsetFilter = searchParams.get("offset");
  // const limitFilter = searchParams.get("limit");

  // const setOffsetFilter = (value) => {
  //   setSearchParams((prevParams) => {
  //     if (value !== undefined && value !== null)
  //       prevParams.set("offset", value.toString());
  //     else {
  //       prevParams.delete("offset");
  //     }
  //     return prevParams;
  //   });
  // };
  // const setLimitFilter = (value) => {
  //   setSearchParams((prevParams) => {
  //     if (value !== undefined && value !== null)
  //       prevParams.set("limit", value.toString());
  //     else {
  //       prevParams.delete("limit");
  //     }
  //     return prevParams;
  //   });
  // };

  const setColorFilter = (value) => {
    setSearchParams((prevParams) => {
      if (value !== undefined && value !== null && value.length)
        prevParams.set("color", JSON.stringify(value));
      else {
        prevParams.delete("color");
      }
      return prevParams;
    });
  };

  const setMaterialFilter = (value) => {
    setSearchParams((prevParams) => {
      if (value !== undefined && value !== null && value.length)
        prevParams.set("material", JSON.stringify(value));
      else {
        prevParams.delete("material");
      }
      return prevParams;
    });
  };

  const setFromFilter = (value) => {
    setSearchParams((prevParams) => {
      if (value !== undefined && value !== null)
        prevParams.set("from", value.toString());
      else {
        prevParams.delete("from");
      }
      return prevParams;
    });
  };

  const setToFilter = (value) => {
    setSearchParams((prevParams) => {
      if (value !== undefined && value !== null)
        prevParams.set("to", value.toString());
      else {
        prevParams.delete("to");
      }
      return prevParams;
    });
  };

  const setSearchName = (value) => {
    setSearchParams((prevParams) => {
      if (value !== undefined && value !== null && value !== "")
        prevParams.set("name", value.toString());
      else {
        prevParams.delete("name");
      }
      return prevParams;
    });
  };

  const searchDebounce = useDebounce(nameSearch?.trim());

  useEffect(() => {
    window.scrollTo(0, 0);
    // dispatch(getDataProduct(null, null, null, null, null));
    dispatch(getDataSimilarColorFilter());
    dispatch(getDataMaterialFilter());
  }, []);

  useEffect(() => {
    try {
      setColorArray(JSON.parse(colorFilterString) ?? []);
    } catch (e) {
      setColorArray([]);
    }
  }, [colorFilterString]);

  useEffect(() => {
    try {
      setMaterialArray(JSON.parse(materialFilterString) ?? []);
    } catch (e) {
      setMaterialArray([]);
    }
  }, [materialFilterString]);

  useEffect(() => {
    dispatch(
      getDataProduct({
        category: categoryFilterString,
        fromPrice: fromFilterString,
        toPrice: toFilterString,
        colors: colorArray,
        materials: materialArray,
        limit: undefined,
        offset: undefined,
        name: searchDebounce,
      })
    );
  }, [
    fromFilterString,
    toFilterString,
    categoryFilterString,
    colorArray,
    materialArray,
    // limitFilter,
    // offsetFilter,
    searchDebounce,
  ]);

  return (
    <section className="search--page">
      <div className="search--page--category">
        <SearchTextField
          value={nameSearch}
          setValue={(value) => setSearchName(value)}
        />
        {searchDebounce && (
          <>
            <span className="search--page--category-result">
              Hiển thị {dataProductCategory.totalProducts} kết quả cho từ khóa{" "}
              <b>"{searchDebounce}"</b>
            </span>
            <span className="mobile--search--page--category-result">
              Top sản phẩm
            </span>
          </>
        )}
      </div>
      {searchDebounce && (
        <>
          <div className="search--page--tab">
            <TabLarge title={"Tất cả"} />
            <TabLarge title={"Sản phẩm"} />
            <TabLarge title={"Tin tức"} />
          </div>
          <div className="search--page--content--result">
            <div className="search--page--content--result-title">
              <div className="mobile--product--list--title--filter-item">
                <ButtonDropdown
                  isTranslateX={true}
                  title={"Bộ lọc"}
                  badge={
                    (fromFilterString !== null && toFilterString !== null
                      ? 1
                      : 0) +
                      (colorArray.length ? 1 : 0) +
                      (materialArray.length ? 1 : 0) ?? undefined
                  }
                  id={"filterBodyMobile"}
                  container={
                    <MobileFilter
                      id={"filterBodyMobile"}
                      minValue={fromFilterString}
                      maxValue={toFilterString}
                      minRange={minRange}
                      maxRange={maxRange}
                      minStep={minStep}
                      setFromFilter={setFromFilter}
                      setToFilter={setToFilter}
                      materialFilter={materialArray}
                      setMaterialFilter={setMaterialFilter}
                      colorFilter={colorArray}
                      setColorFilter={setColorFilter}
                    />
                  }
                />
              </div>
              <div className="filter">
                <div className="item">
                  <ButtonDropdownPrice
                    id={"priceFilter"}
                    minStep={minStep}
                    minRange={minRange}
                    maxRange={maxRange}
                    minValue={fromFilterString}
                    maxValue={toFilterString}
                    setFromFilter={setFromFilter}
                    setToFilter={setToFilter}
                    fromFilter={fromFilterString}
                    toFilter={toFilterString}
                  />
                  <ButtonDropdownColor
                    id={"colorFilter"}
                    colorFilter={colorArray}
                    setColorFilter={setColorFilter}
                  />
                  <ButtonDropdownMaterial
                    id={"materialFilter"}
                    materialFilter={materialArray}
                    setMaterialFilter={setMaterialFilter}
                  />
                </div>
              </div>
            </div>
            <div className="search--page--content--result-list">
              {dataProductCategory?.products?.map((product) => (
                <div className="result--list-item" key={product._id}>
                  <ProductCard product={product} />
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </section>
  );
};

export default SearchPage;
