export const SectionPath = {
  HEADER: "section/webui/header",
  HERO__IIEMS: "section/webui/hero__items",
  PRODUCT_GROUP: "section/webui/product_group",
  VIDEO: "section/webui/video",
  KEY_FEATURE: "section/webui/key_feature",
  FEATURE_ITEMS: "section/webui/feature__items",
  ELECTRIC_SOFA: "section/webui/electric_sofa",
  KEY_DETAILS: "section/webui/key_details__items",
  MECHANICAL_SOFA: "section/webui/mechanical_sofa",
  INTRO_SHOWROOM: "section/webui/intro_showroom",
  VIRTUAL: "section/webui/virtual",
  INSPIRE: "section/webui/inspire",
  MORE_ELECTRIC_SOFA: "section/webui/more_electric_sofa",
  MORE_MECHANICAL_SOFA: "section/webui/more_electric_mechanical",

  PROMOTION_ITEMS: "section/webui/promotion__items",
  INFO_ERGONOMIC: "section/webui/info_ergonomic",
  INFO_ERGONOMIC_ITEMS: "section/webui/info_ergonomic__items",
};

export const ProductPath = {
  PROD_ELECTRIC_SOFA: "products?category=2914567750761112",
  PROD_MECHANICAL_SOFA: "products?category=4114077396888020",
};
export const CategoryId = {
  ELECTRIC_SOFA: "2914567750761112",
  MECHANICAL_SOFA: "4114077396888020",
};

export const Product = {
  PRODUCTS: "products",
  CATEGORIES: "categories",
  FEATURES: "features",
  TAGS: "tags",
};

export const FEATURES = {
  SIMILAR_COLOR: "similar_color",
  MATERIAL: "material",
};

export const TAGS = {
  DESIGN: "design",
  STYLE: "style",
};
