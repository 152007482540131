import "./Button.scss";

const ButtonOutlineSmall = ({ title, style, type, onClick }) => {
  return (
    <button
      style={{ ...style }}
      className="btn--outline small"
      type={type}
      onClick={onClick}
    >
      {title}
    </button>
  );
};
export default ButtonOutlineSmall;
