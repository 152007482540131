import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  featureDetail: {},
};

export const FeatureDetailReducer = createSlice({
  name: "featureDetail",
  initialState,
  reducers: {
    getFeatureDetail: (state, action) => {
      state.featureDetail = action.payload;
    },
    removeFeatureDetail: (state, action) => {
      if (state.featureDetail?.product !== action.payload) {
        state.featureDetail = {};
      }
    },
  },
});

export const { getFeatureDetail, removeFeatureDetail } =
  FeatureDetailReducer.actions;

export default FeatureDetailReducer.reducer;
