import "./Chip.scss";

const ChipSuggestIcon32 = ({ title, item, onClick, isSelected }) => {
  return (
    <div
      className={`chip--suggest--icon ${isSelected ? "selected" : ""}`}
      onClick={onClick}
    >
      <div className="chip--suggest--icon-item">{item}</div>
      <div className="chip--suggest--icon-title">
        <span>{title}</span>
      </div>
    </div>
  );
};

export default ChipSuggestIcon32;
