const ICVisa = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="20"
      viewBox="0 0 29 20"
      fill="none"
    >
      <rect x="0.666992" width="28.3333" height="20" rx="1" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.62553 13.2152H7.90908L6.62195 8.16031C6.56086 7.92779 6.43115 7.72222 6.24034 7.62534C5.76415 7.38186 5.23942 7.18809 4.66699 7.09036V6.89575H7.43206C7.81368 6.89575 8.09989 7.18809 8.1476 7.52761L8.81543 11.1739L10.531 6.89575H12.1998L9.62553 13.2152ZM13.1538 13.2152H11.5328L12.8676 6.89575H14.4887L13.1538 13.2152ZM16.5859 8.64643C16.6336 8.30607 16.9198 8.11145 17.2537 8.11145C17.7785 8.06259 18.3501 8.16032 18.8271 8.40295L19.1133 7.04234C18.6363 6.84773 18.1116 6.75 17.6354 6.75C16.062 6.75 14.9172 7.62534 14.9172 8.8402C14.9172 9.7644 15.7281 10.2497 16.3005 10.542C16.9198 10.8335 17.1583 11.0281 17.1106 11.3196C17.1106 11.7569 16.6336 11.9515 16.1574 11.9515C15.585 11.9515 15.0126 11.8057 14.4887 11.5623L14.2025 12.9237C14.7749 13.1663 15.3942 13.2641 15.9666 13.2641C17.7308 13.3121 18.8271 12.4376 18.8271 11.125C18.8271 9.47206 16.5859 9.37518 16.5859 8.64643ZM24.5003 13.2152L23.2132 6.89575H21.8307C21.5444 6.89575 21.2582 7.09036 21.1628 7.38186L18.7794 13.2152H20.4481L20.7812 12.2918H22.8316L23.0224 13.2152H24.5003ZM22.0692 8.59757L22.5454 10.9793H21.2105L22.0692 8.59757Z"
        fill="#172B85"
      />
    </svg>
  );
};

export default ICVisa;
