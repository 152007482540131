const ICChevronDown = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{
        width: `${width ? width + "px" : "100%"}`,
        height: `${height ? height + "px" : "auto"}`,
      }}
      viewBox={`0 0 24 24`}
      fill="currentColor"
    >
      <path d="M17.3539 9.69977L11.7001 15.3535L6.04639 9.69977L7.10014 8.64602L11.7001 13.246L16.3001 8.64601L17.3539 9.69977Z" />
    </svg>
  );
};
export default ICChevronDown;
