const ICExpand = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: width ?? "100%", height: height ?? "auto" }}
      viewBox={`0 0 24 24`}
      fill="currentColor"
    >
      <path d="M5.5 18.5V13H7V17H11V18.5H5.5ZM17 11V7H13V5.5H18.5V11H17Z" />
    </svg>
  );
};
export default ICExpand;
