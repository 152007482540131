import React, { useEffect, useRef, useState } from "react";
import "./ModalCustom.scss";

export default function ModalCustom({
  featureDetail,
  featureData,
  dataCustom,
  setDataCustom,
  dataCustomTemp,
  setDataCustomTemp,
  close,
}) {
  const [optionActive, setOptionActive] = useState(null);
  const [lstSubFeature, setLstSubFeature] = useState([]);

  useEffect(() => {
    if (featureData && dataCustom && dataCustom?.option[featureData?._id]) {
      const result = featureData?.sub_features?.find(
        (item) => item._id === dataCustom.option[featureData._id].optionId
      );

      result?.sub_features && setLstSubFeature(result.sub_features);

      setOptionActive({
        id: dataCustom.option[featureData._id].optionId,
        title: dataCustom.option[featureData._id].optionName,
      });
    }
  }, [featureData, dataCustom]);

  return (
    <main className="modal-custom">
      <section className="d-flex flex-column">
        <div className="modal-custom__header">
          <h3 className="modal-custom__header__title">{featureData?.title}</h3>

          <button
            className="modal-custom__header__close"
            onClick={() => {
              setDataCustom(null);
              close();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M6.39994 18.6542L5.34619 17.6004L10.9462 12.0004L5.34619 6.40043L6.39994 5.34668L11.9999 10.9467L17.5999 5.34668L18.6537 6.40043L13.0537 12.0004L18.6537 17.6004L17.5999 18.6542L11.9999 13.0542L6.39994 18.6542Z"
                fill="#121212"
              />
            </svg>
          </button>
        </div>

        <div className="modal-custom__image">
          <img src={featureDetail?.images && featureDetail?.images[0]} alt="" />
        </div>

        <div className="modal-custom__body">
          <div className="modal-custom__body__option">
            {featureData?.sub_features?.map((sItem) => {
              return (
                <button
                  key={sItem._id}
                  className={
                    sItem._id === optionActive?.id
                      ? "btn-underline btn-underline--active"
                      : "btn-underline hover-underline-animation"
                  }
                  onClick={() => {
                    setOptionActive({ id: sItem._id, title: sItem.title });
                    setLstSubFeature(sItem.sub_features);
                  }}
                >
                  {sItem.title}
                </button>
              );
            })}
          </div>

          <div className="modal-custom__body__content">
            {lstSubFeature?.map((ssItem) => {
              return (
                <div
                  key={ssItem._id}
                  className={
                    dataCustom &&
                    dataCustom?.option &&
                    ssItem._id ===
                      dataCustom.option[featureData._id]?.optionItemId
                      ? "modal-custom__body__content__item modal-custom__body__content__item--active"
                      : "modal-custom__body__content__item"
                  }
                  onClick={() => {
                    if (
                      dataCustom &&
                      dataCustom?.option &&
                      ssItem._id !==
                        dataCustom.option[featureData._id]?.optionItemId
                    ) {
                      // setDataCustom({
                      //   ...dataCustom,
                      //   option[featureData._id]: {
                      //     optionId: optionActive.id,
                      //     optionName: optionActive.title,
                      //     optionItemId: ssItem._id,
                      //     optionItemName: ssItem.title,
                      //     optionItemIcon: ssItem.icon,
                      //   },
                      // });

                      setDataCustom((prevDataCustom) => ({
                        ...prevDataCustom,
                        option: {
                          ...prevDataCustom.option,
                          [featureData._id]: {
                            optionId: optionActive.id,
                            optionName: optionActive.title,
                            optionItemId: ssItem._id,
                            optionItemName: ssItem.title,
                            optionItemIcon: ssItem.icon,
                          },
                        },
                      }));
                    }
                  }}
                >
                  <img src={ssItem.icon} alt="" />

                  <p>{ssItem.title}</p>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <div className="modal-custom__footer">
        <p className="modal-custom__footer__price">
          {featureDetail?.price_after?.toLocaleString("vi-VN", {
            style: "currency",
            currency: "VND",
          }) || "00 ₫"}
        </p>

        <button
          className="btn-main"
          onClick={() => {
            setDataCustomTemp(dataCustom);
            close();
          }}
        >
          Hoàn thành
        </button>
      </div>
    </main>
  );
}
