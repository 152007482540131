import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./assets/css/animation.scss";
import MainLayout from "./layouts/MainLayout";
import routers from "./routers/router";
import PrivatePage from "./layouts/PrivatePage";
import AOS from "aos";
import "aos/dist/aos.css";

function App() {
  const token = Cookies.get("token");
  const [isLogin, setIsLogin] = useState(true);

  useEffect(() => {
    if (token) {
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
  }, [token]);

  useEffect(() => {
    AOS.init();
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <div>
      <BrowserRouter>
        <Routes>
          {routers.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={
                route.private === true ? (
                  route.isChild ? (
                    <PrivatePage>
                      <MainLayout>{route.element}</MainLayout>
                    </PrivatePage>
                  ) : (
                    <PrivatePage>{route.element}</PrivatePage>
                  )
                ) : (
                  <MainLayout>{route.element}</MainLayout>
                )
              }
            />
          ))}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
