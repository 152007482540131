import callApi from "../../utils/callApi";
import { getMechanicalSofa } from "../reducers/mechanicalSofa.reducers";

export const getMechanicalSofaAction = (path) => {
  return async (dispatch) => {
    try {
      const res = await callApi(path, "GET", null);

      await dispatch(getMechanicalSofa(res.data.data));
    } catch (err) {
      console.log(err);
    }
  };
};
