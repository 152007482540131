const ICCart = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: width ?? "100%", height: height ?? "auto" }}
      viewBox={`0 0 32 32`}
      fill="currentColor"
    >
      <path d="M8.41033 28.6668C7.73678 28.6668 7.16667 28.4335 6.7 27.9668C6.23333 27.5002 6 26.93 6 26.2565V11.0772C6 10.4036 6.23333 9.8335 6.7 9.36683C7.16667 8.90016 7.73678 8.66683 8.41033 8.66683H11V8.3335C11 6.95238 11.488 5.77372 12.464 4.7975C13.4402 3.8215 14.6189 3.3335 16 3.3335C17.3811 3.3335 18.5598 3.8215 19.536 4.7975C20.512 5.77372 21 6.95238 21 8.3335V8.66683H23.5897C24.2632 8.66683 24.8333 8.90016 25.3 9.36683C25.7667 9.8335 26 10.4036 26 11.0772V26.2565C26 26.93 25.7667 27.5002 25.3 27.9668C24.8333 28.4335 24.2632 28.6668 23.5897 28.6668H8.41033ZM8.41033 26.6668H23.5897C23.6923 26.6668 23.7863 26.6241 23.8717 26.5385C23.9572 26.4532 24 26.3592 24 26.2565V11.0772C24 10.9745 23.9572 10.8805 23.8717 10.7952C23.7863 10.7096 23.6923 10.6668 23.5897 10.6668H21V13.6668C21 13.9506 20.9042 14.1882 20.7127 14.3795C20.5213 14.5711 20.2838 14.6668 20 14.6668C19.7162 14.6668 19.4787 14.5711 19.2873 14.3795C19.0958 14.1882 19 13.9506 19 13.6668V10.6668H13V13.6668C13 13.9506 12.9042 14.1882 12.7127 14.3795C12.5213 14.5711 12.2838 14.6668 12 14.6668C11.7162 14.6668 11.4787 14.5711 11.2873 14.3795C11.0958 14.1882 11 13.9506 11 13.6668V10.6668H8.41033C8.30767 10.6668 8.21367 10.7096 8.12833 10.7952C8.04278 10.8805 8 10.9745 8 11.0772V26.2565C8 26.3592 8.04278 26.4532 8.12833 26.5385C8.21367 26.6241 8.30767 26.6668 8.41033 26.6668ZM13 8.66683H19V8.3335C19 7.49772 18.709 6.78872 18.127 6.2065C17.545 5.6245 16.836 5.3335 16 5.3335C15.164 5.3335 14.455 5.6245 13.873 6.2065C13.291 6.78872 13 7.49772 13 8.3335V8.66683Z" />
    </svg>
  );
};
export default ICCart;
