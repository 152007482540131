import "./Button.scss";

const ButtonIconSolidMedium = ({ icon, disabled, type }) => {
  return (
    <button className={`btn--icon--solid medium`} disabled={disabled} type={type}>
      {icon}
    </button>
  );
};
export default ButtonIconSolidMedium;
