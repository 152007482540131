const ICMaster = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="20"
      viewBox="0 0 29 20"
      fill="none"
    >
      <rect x="0.000488281" width="28.3333" height="20" rx="1" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3163 14.0243C13.3296 14.856 12.0497 15.3581 10.6511 15.3581C7.53034 15.3581 5.00049 12.8583 5.00049 9.77474C5.00049 6.69115 7.53034 4.19141 10.6511 4.19141C12.0497 4.19141 13.3296 4.69349 14.3163 5.52519C15.303 4.69349 16.5829 4.19141 17.9815 4.19141C21.1023 4.19141 23.6321 6.69115 23.6321 9.77474C23.6321 12.8583 21.1023 15.3581 17.9815 15.3581C16.5829 15.3581 15.303 14.856 14.3163 14.0243Z"
        fill="#ED0006"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3164 14.0243C15.5314 13.0002 16.3017 11.4764 16.3017 9.77474C16.3017 8.07311 15.5314 6.54927 14.3164 5.52519C15.3031 4.69349 16.583 4.19141 17.9816 4.19141C21.1024 4.19141 23.6322 6.69115 23.6322 9.77474C23.6322 12.8583 21.1024 15.3581 17.9816 15.3581C16.583 15.3581 15.3031 14.856 14.3164 14.0243Z"
        fill="#F9A000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3164 14.0245C15.5313 13.0004 16.3017 11.4765 16.3017 9.77492C16.3017 8.0733 15.5313 6.54948 14.3164 5.52539C13.1014 6.54948 12.3311 8.0733 12.3311 9.77492C12.3311 11.4765 13.1014 13.0004 14.3164 14.0245Z"
        fill="#FF5E00"
      />
    </svg>
  );
};

export default ICMaster;
