import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  similarSofa: [],
};

export const SimilarSofaReducer = createSlice({
  name: "similarSofa",
  initialState,
  reducers: {
    getSimilarSofa: (state, action) => {
      state.similarSofa = action.payload;
    },
  },
});

export const { getSimilarSofa } = SimilarSofaReducer.actions;

export default SimilarSofaReducer.reducer;
