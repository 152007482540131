const ICJCB = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="20"
      viewBox="0 0 29 20"
      fill="none"
    >
      <rect x="0.333496" width="28.3333" height="20" rx="1" fill="white" />
      <path
        d="M18.6517 9.96219C19.0944 9.97181 19.5396 9.94264 19.9806 9.97734C20.4271 10.0607 20.5347 10.7367 20.138 10.9581C19.8673 11.1039 19.5457 11.0124 19.2523 11.0381H18.6517V9.96219ZM20.2365 8.74436C20.3349 9.09154 20.0002 9.40326 19.6658 9.35545H18.6517C18.6588 9.02805 18.6377 8.67268 18.662 8.36251C19.0682 8.37395 19.4783 8.33918 19.8822 8.3807C20.0558 8.4243 20.201 8.56698 20.2365 8.74436ZM22.6773 3.59573C22.6962 4.25876 22.68 4.95684 22.6855 5.63332C22.6842 8.38365 22.6881 11.1339 22.6833 13.8844C22.6655 14.9151 21.752 15.8108 20.7284 15.8314C19.7038 15.8355 18.679 15.832 17.6542 15.8331V11.6752C18.7708 11.6695 19.8878 11.6869 21.0039 11.6665C21.5217 11.634 22.0888 11.2924 22.1129 10.7226C22.1738 10.1504 21.6343 9.75461 21.1221 9.6921C20.9252 9.68699 20.931 9.63471 21.1221 9.6119C21.6106 9.50631 21.9943 9.00069 21.8505 8.49432C21.7281 7.96172 21.1394 7.75559 20.6498 7.75661C19.6514 7.74983 18.6529 7.75566 17.6545 7.75369C17.6609 6.97746 17.641 6.20039 17.6652 5.42472C17.7443 4.41258 18.6808 3.57788 19.69 3.59599C20.6859 3.59584 21.6816 3.59584 22.6773 3.5958V3.59573Z"
        fill="url(#paint0_linear_687_2350)"
      />
      <path
        d="M6.37226 5.52925C6.39776 4.50013 7.31515 3.61183 8.33745 3.59679C9.35828 3.59365 10.3793 3.59634 11.4001 3.59543C11.3973 7.03865 11.4056 10.4822 11.3959 13.9253C11.3565 14.9419 10.4491 15.8133 9.43799 15.8313C8.41523 15.835 7.39236 15.8318 6.36957 15.8328V11.5347C7.36304 11.7694 8.40485 11.8693 9.41836 11.7136C10.0242 11.6162 10.687 11.3186 10.8921 10.6901C11.0431 10.1525 10.9581 9.58667 10.9806 9.03487V7.75339H9.22659C9.21871 8.60093 9.24273 9.44994 9.21389 10.2965C9.16661 10.8168 8.65133 11.1474 8.1606 11.1297C7.55201 11.1361 6.346 10.6886 6.346 10.6886C6.34297 9.10071 6.36366 7.11207 6.37226 5.5294V5.52925Z"
        fill="url(#paint1_linear_687_2350)"
      />
      <path
        d="M12.0543 8.27047C11.962 8.29006 12.0357 7.95598 12.0121 7.82926C12.0184 7.02798 11.999 6.22583 12.0229 5.42512C12.1017 4.40869 13.0454 3.57192 14.0587 3.59571H17.0429C17.0401 7.03893 17.0484 10.4825 17.0387 13.9256C16.9993 14.9422 16.0918 15.8135 15.0807 15.8315C14.0579 15.8354 13.035 15.8321 12.0121 15.8331V11.124C12.7107 11.6971 13.6601 11.7864 14.5304 11.7879C15.1865 11.7877 15.8388 11.6865 16.4759 11.5352V10.6725C15.7579 11.0303 14.9138 11.2577 14.1178 11.0521C13.5626 10.9139 13.1595 10.3772 13.1685 9.80423C13.1042 9.20832 13.4535 8.5792 14.0391 8.40205C14.7661 8.17443 15.5585 8.34852 16.2401 8.64444C16.3861 8.72089 16.5343 8.81572 16.4759 8.57166V7.89355C15.3361 7.62236 14.1231 7.52257 12.978 7.81759C12.6465 7.91109 12.3236 8.05289 12.0543 8.27055V8.27047Z"
        fill="url(#paint2_linear_687_2350)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_687_2350"
          x1="17.6199"
          y1="9.45279"
          x2="22.6537"
          y2="9.45279"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#58B03A" />
          <stop offset="1" stopColor="#55B330" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_687_2350"
          x1="6.26254"
          y1="9.8313"
          x2="11.3176"
          y2="9.8313"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0F6EB6" />
          <stop offset="1" stopColor="#006DBA" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_687_2350"
          x1="12.0047"
          y1="9.57132"
          x2="17.0419"
          y2="9.57132"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DE0D3D" />
          <stop offset="1" stopColor="#E30138" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ICJCB;
