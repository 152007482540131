import { useEffect } from "react";

const InputDualRange = ({
  id,
  minValue,
  setMinValue,
  maxValue,
  setMaxValue,
  minRange,
  maxRange,
  minStep,
  minLabel,
  maxLabel,
}) => {
  function fillColor() {
    let sliderTrack = document.getElementById(`slider-track-${id}`);

    const percent1 = (minValue / maxRange) * 100;
    const percent2 = (maxValue / maxRange) * 100;

    if (sliderTrack)
      sliderTrack.style.background = `linear-gradient(to right, #dadae5 ${percent1}% , #1D1D1F ${percent1}% , #1D1D1F ${percent2}%, #dadae5 ${percent2}%)`;
  }

  useEffect(() => {
    fillColor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minValue, maxValue]);

  return (
    <div className="container--slider">
      <div className="wrapper--input--range">
        <div className="container--input--range">
          <div className="slider-track" id={`slider-track-${id}`}></div>
          <input
            type="range"
            min={minRange}
            max={maxRange}
            step={minStep}
            value={minValue}
            id={"slider-1" + id}
            onChange={(e) => {
              if (maxValue - parseInt(e.target.value) >= minStep) {
                setMinValue(e.target.value);
                // fillColor();
              }
            }}
            // onInput={slideOne()}
          />
          <input
            type="range"
            min={minRange}
            max={maxRange}
            step={minStep}
            value={maxValue}
            id={"slider-2" + id}
            onChange={(e) => {
              if (parseInt(e.target.value) - minValue >= minStep) {
                setMaxValue(e.target.value);
                // fillColor();
              }
            }}
          />
        </div>
      </div>
      <div className="container--slider-label">
        <span id={"slider-1-value" + id}>{minLabel}</span>
        <span id={"slider-2-value" + id}>{maxLabel}</span>
      </div>
    </div>
  );
};

export default InputDualRange;
