import "./Button.scss";

const ButtonOutlineMedium = ({ title, style, type, onClick }) => {
  return (
    <button
      style={{ ...style }}
      className="btn--outline medium"
      type={type}
      onClick={onClick}
    >
      {title}
    </button>
  );
};
export default ButtonOutlineMedium;
